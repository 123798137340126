export const BuyContentList1 = [
  {
    BuyContentItemName: '聊天、OA、订阅',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '行政管理',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '项目管理',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '研发管理',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '产品管理',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '解决方案',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '案例',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '课程',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '技术服务',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '展示方案',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '演示方案',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: 'CRM(标准版)',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '合同管理(含合同应收、应付管理)',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '会议系统',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '资产管理',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '进销存',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '招标管理(考虑定制收费)',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '投标管理(AI写标书模块收费)',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '摄像头管理',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '轻量化引擎(格兰戴尔)',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '族库管理',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '图纸对比',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: 'BI报表',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: 'AI助手(系列)',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '企业空间(100G为标准)',
    BuyContentItemNumber: ''
  },
]
export const BuyContentList2 = [
  {
    BuyContentItemName: '聊天、OA、订阅',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '行政管理',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '项目管理',
    BuyContentItemNumber: '180元'
  },
  {
    BuyContentItemName: '研发管理',
    BuyContentItemNumber: '180元'
  },
  {
    BuyContentItemName: '产品管理',
    BuyContentItemNumber: '9元'
  },
  {
    BuyContentItemName: '解决方案',
    BuyContentItemNumber: '9元'
  },
  {
    BuyContentItemName: '案例',
    BuyContentItemNumber: '9元'
  },
  {
    BuyContentItemName: '课程',
    BuyContentItemNumber: '9元'
  },
  {
    BuyContentItemName: '技术服务',
    BuyContentItemNumber: '9元'
  },
  {
    BuyContentItemName: '展示方案',
    BuyContentItemNumber: '13.5元'
  },
  {
    BuyContentItemName: '演示方案',
    BuyContentItemNumber: '13.5元'
  },
  {
    BuyContentItemName: 'CRM(标准版)',
    BuyContentItemNumber: '270元'
  },
  {
    BuyContentItemName: '合同管理(含合同应收、应付管理)',
    BuyContentItemNumber: '450元'
  },
  {
    BuyContentItemName: '会议系统',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '资产管理',
    BuyContentItemNumber: '9元'
  },
  {
    BuyContentItemName: '进销存',
    BuyContentItemNumber: '135元'
  },
  {
    BuyContentItemName: '招标管理(考虑定制收费)',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '投标管理(AI写标书模块收费)',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '摄像头管理',
    BuyContentItemNumber: '9元'
  },
  {
    BuyContentItemName: '轻量化引擎(格兰戴尔)',
    BuyContentItemNumber: '135元'
  },
  {
    BuyContentItemName: '族库管理',
    BuyContentItemNumber: '108元'
  },
  {
    BuyContentItemName: '图纸对比',
    BuyContentItemNumber: '90元'
  },
  {
    BuyContentItemName: 'BI报表',
    BuyContentItemNumber: '135元'
  },
  {
    BuyContentItemName: 'AI助手(系列)',
    BuyContentItemNumber: '180元'
  },
  {
    BuyContentItemName: '企业空间(100G为标准)',
    BuyContentItemNumber: '270元'
  },
]
export const BuyContentList3 = [
  {
    BuyContentItemName: '聊天、OA、订阅',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '行政管理',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '项目管理',
    BuyContentItemNumber: '160元'
  },
  {
    BuyContentItemName: '研发管理',
    BuyContentItemNumber: '160元'
  },
  {
    BuyContentItemName: '产品管理',
    BuyContentItemNumber: '8元'
  },
  {
    BuyContentItemName: '解决方案',
    BuyContentItemNumber: '8元'
  },
  {
    BuyContentItemName: '案例',
    BuyContentItemNumber: '8元'
  },
  {
    BuyContentItemName: '课程',
    BuyContentItemNumber: '8元'
  },
  {
    BuyContentItemName: '技术服务',
    BuyContentItemNumber: '8元'
  },
  {
    BuyContentItemName: '展示方案',
    BuyContentItemNumber: '12元'
  },
  {
    BuyContentItemName: '演示方案',
    BuyContentItemNumber: '12元'
  },
  {
    BuyContentItemName: 'CRM(标准版)',
    BuyContentItemNumber: '240元'
  },
  {
    BuyContentItemName: '合同管理(含合同应收、应付管理)',
    BuyContentItemNumber: '400元'
  },
  {
    BuyContentItemName: '会议系统',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '资产管理',
    BuyContentItemNumber: '8元'
  },
  {
    BuyContentItemName: '进销存',
    BuyContentItemNumber: '120元'
  },
  {
    BuyContentItemName: '招标管理(考虑定制收费)',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '投标管理(AI写标书模块收费)',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '摄像头管理',
    BuyContentItemNumber: '8元'
  },
  {
    BuyContentItemName: '轻量化引擎(格兰戴尔)',
    BuyContentItemNumber: '120元'
  },
  {
    BuyContentItemName: '族库管理',
    BuyContentItemNumber: '96元'
  },
  {
    BuyContentItemName: '图纸对比',
    BuyContentItemNumber: '80元'
  },
  {
    BuyContentItemName: 'BI报表',
    BuyContentItemNumber: '120元'
  },
  {
    BuyContentItemName: 'AI助手(系列)',
    BuyContentItemNumber: '160元'
  },
  {
    BuyContentItemName: '企业空间(100G为标准)',
    BuyContentItemNumber: '240元'
  },
]

// 企业版（BuyContentList4）
// 假设：图片中企业版与团队版类似，但“招标管理”“投标管理”等高级功能也内置，全部免费
// （同样仅作演示，实际请根据你的图片填写）
export const BuyContentList4 = [
  {
    BuyContentItemName: '聊天、OA、订阅',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '行政管理',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '项目管理',
    BuyContentItemNumber: '130元'
  },
  {
    BuyContentItemName: '研发管理',
    BuyContentItemNumber: '130元'
  },
  {
    BuyContentItemName: '产品管理',
    BuyContentItemNumber: '6.5元'
  },
  {
    BuyContentItemName: '解决方案',
    BuyContentItemNumber: '6.5元'
  },
  {
    BuyContentItemName: '案例',
    BuyContentItemNumber: '6.5元'
  },
  {
    BuyContentItemName: '课程',
    BuyContentItemNumber: '6.5元'
  },
  {
    BuyContentItemName: '技术服务',
    BuyContentItemNumber: '6.5元'
  },
  {
    BuyContentItemName: '展示方案',
    BuyContentItemNumber: '9.75元'
  },
  {
    BuyContentItemName: '演示方案',
    BuyContentItemNumber: '9.75元'
  },
  {
    BuyContentItemName: 'CRM(标准版)',
    BuyContentItemNumber: '195元'
  },
  {
    BuyContentItemName: '合同管理(含合同应收、应付管理)',
    BuyContentItemNumber: '325元'
  },
  {
    BuyContentItemName: '会议系统',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '资产管理',
    BuyContentItemNumber: '6.5元'
  },
  {
    BuyContentItemName: '进销存',
    BuyContentItemNumber: '97.5元'
  },
  {
    BuyContentItemName: '招标管理(考虑定制收费)',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '投标管理(AI写标书模块收费)',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '摄像头管理',
    BuyContentItemNumber: '6.5元'
  },
  {
    BuyContentItemName: '轻量化引擎(格兰戴尔)',
    BuyContentItemNumber: '97.5元'
  },
  {
    BuyContentItemName: '族库管理',
    BuyContentItemNumber: '78元'
  },
  {
    BuyContentItemName: '图纸对比',
    BuyContentItemNumber: '65元'
  },
  {
    BuyContentItemName: 'BI报表',
    BuyContentItemNumber: '97.5元'
  },
  {
    BuyContentItemName: 'AI助手(系列)',
    BuyContentItemNumber: '130元'
  },
  {
    BuyContentItemName: '企业空间(100G为标准)',
    BuyContentItemNumber: '195元'
  },
];

export const BuyContentList5 = [
  {
    BuyContentItemName: '聊天、OA、订阅',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '行政管理',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '项目管理',
    BuyContentItemNumber: '100元'
  },
  {
    BuyContentItemName: '研发管理',
    BuyContentItemNumber: '100元'
  },
  {
    BuyContentItemName: '产品管理',
    BuyContentItemNumber: '5元'
  },
  {
    BuyContentItemName: '解决方案',
    BuyContentItemNumber: '5元'
  },
  {
    BuyContentItemName: '案例',
    BuyContentItemNumber: '5元'
  },
  {
    BuyContentItemName: '课程',
    BuyContentItemNumber: '5元'
  },
  {
    BuyContentItemName: '技术服务',
    BuyContentItemNumber: '5元'
  },
  {
    BuyContentItemName: '展示方案',
    BuyContentItemNumber: '7.5元'
  },
  {
    BuyContentItemName: '演示方案',
    BuyContentItemNumber: '7.5元'
  },
  {
    BuyContentItemName: 'CRM(标准版)',
    BuyContentItemNumber: '150元'
  },
  {
    BuyContentItemName: '合同管理(含合同应收、应付管理)',
    BuyContentItemNumber: '250元'
  },
  {
    BuyContentItemName: '会议系统',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '资产管理',
    BuyContentItemNumber: '5元'
  },
  {
    BuyContentItemName: '进销存',
    BuyContentItemNumber: '75元'
  },
  {
    BuyContentItemName: '招标管理(考虑定制收费)',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '投标管理(AI写标书模块收费)',
    BuyContentItemNumber: ''
  },
  {
    BuyContentItemName: '摄像头管理',
    BuyContentItemNumber: '5元'
  },
  {
    BuyContentItemName: '轻量化引擎(格兰戴尔)',
    BuyContentItemNumber: '75元'
  },
  {
    BuyContentItemName: '族库管理',
    BuyContentItemNumber: '60元'
  },
  {
    BuyContentItemName: '图纸对比',
    BuyContentItemNumber: '50元'
  },
  {
    BuyContentItemName: 'BI报表',
    BuyContentItemNumber: '75元'
  },
  {
    BuyContentItemName: 'AI助手(系列)',
    // 假设高级版对 AI 助手收取额外费用
    BuyContentItemNumber: '100元'
  },
  {
    BuyContentItemName: '企业空间(100G为标准)',
    BuyContentItemNumber: '150元'
  },
];