<template>
  <div>
    <div class="section_4">
      <div class="PricingBox">
        <div class="PricingCardBox">
          <div class="PricingCardBoxFont">为您的组织选择最佳方案</div>
          <div class="PricingCard">
            <div
              :class="{
                [card.cardClass]: true,
              }"
              v-for="(card, index) in pricingCard"
            >
              <div class="PricingCardItemTop">
                <div class="PricingPreferential">
                  <div class="PricingPreferentialTitle">{{ card.title }}</div>
                </div>
                <div class="TeamCoKind">{{ card.version }}</div>
                <div class="PeopleTotal">{{ card.peopleTotal }}</div>
                <div class="PriceKind">
                  <div class="PriceNumber">{{ card.priceNumber }}</div>
                  <div class="PriceUnit" v-if="card.priceUnit != ''">
                    {{ card.priceUnit }}
                  </div>
                </div>
                <div
                  class="FreeUseButton"
                  :style="card.freeUseButton != '' ? '' : 'visibility: hidden;'"
                  @click="openDialog(card)"
                >
                  {{ card.freeUseButton }}
                </div>
                <div class="loadMore" @click="toggleExpand(index)">
                  <p>
                    {{ expanded[index] ? "收起" : "加载更多" }}
                    <i
                      :class="
                        expanded[index]
                          ? 'el-icon-arrow-up'
                          : 'el-icon-arrow-down'
                      "
                    ></i>
                  </p>
                </div>
                <hr
                  v-if="card.priceUnit != ''"
                  :class="{ show: expanded[index] }"
                />
              </div>
              <div
                class="PricingCardItemBottom"
                :class="{ expanded: expanded[index] }"
              >
                <div class="BuyContentList">
                  <div
                    class="BuyContentItem"
                    v-for="item in card.BuyContentList"
                  >
                    <div class="BuyContentItemName">
                      {{ item.BuyContentItemName }}
                    </div>
                    <div
                      class="BuyContentItemNumber"
                      v-if="item.BuyContentItemNumber != ''"
                    >
                      {{ item.BuyContentItemNumber }}
                    </div>
                    <img
                      src="../assets/picture/GoldChecked.png"
                      class="BuyContentItemActive"
                      v-else-if="card.goldChecked === true"
                    />
                    <img
                      src="../assets/picture/GreenChecked.png"
                      class="BuyContentItemActive"
                      v-else
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="选择套餐" :visible.sync="dialogFormVisible">
      <!-- 给子组件传入选中的pricingList数据，子组件中判断是否父组件是否传了
           有数据，有则子组件选中套餐，没有说明是在软件价格页面点击的按钮，则用户自行选择 
      -->
      <PricingSelect :pricingList="selectedPricingList" />
    </el-dialog>
  </div>
</template>
<script>
import {
  BuyContentList1,
  BuyContentList2,
  BuyContentList3,
  BuyContentList4,
  BuyContentList5,
} from "../Data/pricingData.js";
import PricingSelect from "./PricingSelect.vue";
import Vue from "vue";
import ElementUI from "element-ui";
Vue.use(ElementUI);

export default {
  components: { PricingSelect },
  data() {
    return {
      selected: 0,
      isFooterFixed: false,
      timerId: null,
      expanded: Array(5).fill(false),
      BuyContentList1,
      BuyContentList2,
      BuyContentList3,
      BuyContentList4,
      BuyContentList5,

      // 需要更换的数据：
      /**
       * 1. 第一个div的class名；
       * 2. title(免费试用3个月)
       * 3. 版本
       * 4. 限制人数
       * 5. 价格(值可以为按需订阅)
       * 6. button文字
       * 7. BuyContentList的数据
       */
      pricingCard: [
        {
          cardClass: "PricingCardItem",
          title: "标准授权使用",
          version: "个人版",
          peopleTotal: "免费10人",
          priceNumber: "0",
          priceUnit: "元/年",
          freeUseButton: "免费使用",
          BuyContentList: BuyContentList1,
          goldChecked: false,
        },
        {
          cardClass: "PricingCardItem2",
          title: "免费试用3个月",
          version: "基础版",
          peopleTotal: "限30人起",
          priceNumber: "按需订阅",
          priceUnit: "",
          freeUseButton: "永久购买",
          BuyContentList: BuyContentList2,
          goldChecked: false,
        },
        {
          cardClass: "PricingCardItem3",
          title: "免费试用3个月",
          version: "团队版",
          peopleTotal: "限50人起",
          priceNumber: "按需订阅",
          priceUnit: "",
          freeUseButton: "",
          BuyContentList: BuyContentList3,
          goldChecked: false,
        },
        {
          cardClass: "PricingCardItem4",
          title: "免费试用3个月",
          version: "企业版",
          peopleTotal: "限50人起",
          priceNumber: "按需订阅",
          priceUnit: "",
          freeUseButton: "",
          BuyContentList: BuyContentList4,
          goldChecked: false,
        },
        {
          cardClass: "PricingCardItem5",
          title: "免费试用3个月",
          version: "旗舰版",
          peopleTotal: "限100人起",
          priceNumber: "按需订阅",
          priceUnit: "",
          freeUseButton: "",
          BuyContentList: BuyContentList5,
          goldChecked: true,
        },
      ],
      dialogFormVisible: false,
      selectedPricingList: [],
    };
  },
  methods: {
    toggleExpand(index) {
      Vue.set(this.expanded, index, !this.expanded[index]);
    },
    openDialog(card) {
      if (card.version === "个人版") {
        window.open(
          "https://saas.teamco.host:10998/saas/enterprise/#/register-index?loginTitle=Teamco&tenantVersionType=2"
        );
      } else {
        this.dialogFormVisible = true;
        this.selectedPricingList = card.BuyContentList;
      }
    },
  },
  mounted() {
    // 初始化 PricingCard 的位置
    this.$nextTick(() => {
      const cardContainer = this.$el.querySelector(".PricingCard");
      cardContainer.style.left = "0px";
    });
  },
};
</script>

<style lang="less" scoped>
// 第四板块
.section_4 {
  // min-width: 1200px;

  > .PricingBox {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;

    > .PricingCardBox {
      width: 100%;
      overflow: hidden;

      > .PricingCardBoxFont {
        text-align: center;
        font-size: clamp(1.5rem, 4vw, 2.5rem);
        font-weight: 700;
        margin-top: 100px;
      }

      > .PricingCard {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(227px, 1fr));
        justify-items: center;
        grid-gap: 20px;
        margin-top: 40px;
        padding: 40px 20px 80px;

        > .PricingCardItem {
          transition: left 0.5s ease-in-out;
          border-radius: 12px;
          background: rgba(255, 255, 255, 1);
          width: 227px;
          margin: 20px;

          > .PricingCardItemTop {
            padding-left: 24px;
            border-radius: 12px;
            background: rgba(255, 255, 255, 1);
            box-shadow: 0px 0px 20px rgba(29, 33, 41, 0.08);

            > .PricingPreferential {
              display: flex;

              > .PricingPreferentialTitle {
                margin-left: auto;
                width: 124px;
                height: 34px;
                border-radius: 0px 12px 0px 12px;
                background: rgba(229, 229, 229, 1);
                text-align: center;
                line-height: 34px;
                color: rgba(64, 64, 64, 1);
                font-size: 14px;
                font-weight: 400;
              }
            }

            > .TeamCoKind {
              color: rgb(0, 0, 0);
              font-size: 22px;
              font-weight: 500;
            }

            > .PeopleTotal {
              font-size: 14px;
              font-weight: 400;
              color: rgb(0, 0, 0);
              margin-top: 8px;
            }

            > .PriceKind {
              height: 100px;
              display: flex;

              > .PriceNumber {
                font-size: 42px;
                font-weight: 700;
                color: rgba(29, 33, 41, 1);
                line-height: 94px;
              }

              > .PriceUnit {
                color: rgba(78, 89, 105, 1);
                font-size: 14px;
                font-weight: 400;
                line-height: 116px;
              }
            }

            > .FreeUseButton {
              width: 187px;
              height: 48px;
              border-radius: 6px;
              color: rgba(78, 89, 105, 1);
              border: 1px solid rgba(206, 208, 213, 1);
              text-align: center;
              line-height: 48px;
              cursor: pointer;
            }

            > .loadMore {
              color: #4e5969;
              text-align: center;
              font-size: 14px;
              padding: 9px 0;
              cursor: pointer;
              user-select: none;

              p {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
                padding-right: 20px;
              }
            }

            > hr {
              border: none;
              height: 1px;
              background-color: rgba(229, 230, 235, 1);
              display: none;
              &.show {
                display: block;
              }
            }
          }

          > .PricingCardItemBottom {
            padding: 0;
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
            will-change: max-height, opacity, padding;

            &.expanded {
              opacity: 1;
              max-height: 3000px;
              padding: 0 20px 20px 20px;
              box-shadow: 0px 0px 20px rgba(29, 33, 41, 0.08);
            }

            > .BuyContentList {
              > .BuyContentItem {
                display: flex;
                margin-top: 12px;

                > .BuyContentItemName {
                  color: rgba(134, 144, 156, 1);
                  font-size: 14px;
                }

                > .BuyContentItemActive,
                > .BuyContentItemNumber {
                  margin-left: auto;
                  height: 10px;
                }

                > .BuyContentItemNumber {
                  color: rgba(60, 69, 87, 1);
                  font-size: 14px;
                }
              }
            }
          }
        }

        .PricingCardItem2,
        .PricingCardItem3,
        .PricingCardItem4,
        .PricingCardItem5 {
          border-radius: 12px;
          background-repeat: no-repeat;
          width: 227px;
          margin: 20px;

          > .PricingCardItemTop {
            padding-left: 24px;
            box-shadow: 0px 0px 20px rgba(29, 33, 41, 0.08);

            > .PricingPreferential {
              display: flex;

              > .PricingPreferentialTitle {
                margin-left: auto;
                width: 124px;
                height: 34px;
                border-radius: 0px 12px 0px 12px;
                background: rgba(255, 87, 51, 1);
                text-align: center;
                line-height: 34px;
                color: rgba(255, 238, 179, 1);
                font-size: 14px;
                font-weight: 400;
              }
            }

            > .TeamCoKind {
              color: white;
              font-size: 22px;
              font-weight: 500;
            }

            > .PeopleTotal {
              font-size: 14px;
              font-weight: 400;
              color: white;
              margin-top: 8px;
            }

            > .PriceKind {
              height: 100px;
              display: flex;

              > .PriceNumber {
                line-height: 100px;
                font-size: 22px;
                font-weight: 500;
                color: rgba(255, 255, 255, 1);
              }

              > .PriceUnit {
                color: rgba(255, 255, 255, 1);
                font-size: 14px;
                font-weight: 400;
                line-height: 150px;
              }
            }

            > .FreeUseButton {
              width: 187px;
              height: 48px;
              color: rgba(255, 238, 179, 1);
              border-radius: 6px;
              background: linear-gradient(
                90deg,
                rgba(66, 28, 22, 1) 0%,
                rgba(0, 0, 0, 1) 100%
              );
              text-align: center;
              line-height: 48px;
              cursor: pointer;
            }

            > .loadMore {
              color: #fff;
              text-align: center;
              font-size: 14px;
              padding: 9px 0;
              cursor: pointer;
              user-select: none;

              p {
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 4px;
                padding-right: 20px;
              }
            }

            > hr {
              margin-top: 28px;
              border: none;
              height: 1px;
              background-color: rgba(229, 230, 235, 1);
              display: none;
              &.show {
                display: block;
              }
            }
          }

          > .PricingCardItemBottom {
            border-radius: 12px;
            background: rgba(255, 255, 255, 1);
            padding: 0;
            opacity: 0;
            max-height: 0;
            overflow: hidden;
            transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
            will-change: max-height, opacity, padding;

            &.expanded {
              opacity: 1;
              max-height: 3000px;
              padding: 0 20px 20px 20px;
              box-shadow: 0px 0px 20px rgba(29, 33, 41, 0.08);
            }

            > .BuyContentList {
              > .BuyContentItem {
                display: flex;
                margin-top: 12px;

                > .BuyContentItemName {
                  color: rgba(134, 144, 156, 1);
                  font-size: 14px;
                }

                > .BuyContentItemActive,
                > .BuyContentItemNumber {
                  margin-left: auto;
                  height: 10px;
                }

                > .BuyContentItemNumber {
                  color: rgba(60, 69, 87, 1);
                  font-size: 14px;
                }
              }
            }
          }
        }

        .PricingCardItem2 {
          background-image: url(../assets/picture/PricingCardImg2_short.png);
        }

        .PricingCardItem3 {
          background-image: url(../assets/picture/PricingCardImg3_short.png);
        }

        .PricingCardItem4 {
          background-image: url(../assets/picture/PricingCardImg4_short.png);
        }

        .PricingCardItem5 {
          background-image: url(../assets/picture/PricingCardImg5_short.png);

          > .PricingCardItemBottom {
            > .BuyContentList {
              > .BuyContentItem {
                > .BuyContentItemNumber {
                  color: rgba(158, 97, 36, 1);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .section_4 {
    > .PricingBox {
      width: 100%;
    }
  }
}

@keyframes move-left {
  0% {
    transform: translate(0%, -50%) translateX(0);
  }
  100% {
    transform: translate(-50%, -50%) translateX(-100%);
  }
}

@keyframes move-right {
  0% {
    transform: translate(0%, -50%) translateX(-100%);
  }
  100% {
    transform: translate(-50%, -50%) translateX(50%);
  }
}

.move-left {
  animation: move-left 1s forwards;
}

.move-right {
  animation: move-right 1s forwards;
}

::v-deep .el-dialog {
  width: 100%;
  max-width: 1200px;
  min-width: 360px;
}

::v-deep .el-dialog__body {
  padding: 0;
  background: transparent;
}
</style>
