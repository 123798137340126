<template>
  <div class="TopNav">
    <div class="NavBox">
      <div class="NavBox__left">
        <img
          class="handle"
          src="../assets/picture/logo.svg"
          @click="ToHome"
          style="height: 39px"
        />
      </div>
      <div class="NavBox__right">
        <div class="NavBox__right__icon">
          <!-- 人物图标 -->
          <el-dropdown @command="handleCommand">
            <span class="el-dropdown-link">
              <el-avatar
                class="avatar"
                size="small"
                :src="avatarImg"
              ></el-avatar>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="register">免费注册</el-dropdown-item>
              <el-dropdown-item command="login">登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <label class="burger" for="burger">
          <input type="checkbox" id="burger" @click.stop="toggleMenu" />
          <span></span>
          <span></span>
          <span></span>
        </label>
      </div>
    </div>

    <!-- 新增的移动端菜单 -->
    <transition name="slide-down">
      <div class="mobile-menu" v-show="isMenuOpen">
        <!-- 实现一个分类组件
         1. 写左侧的分类项，获取数据，用li标签，v-for循环
         2. 若分类项的数据有children，则可以展开，展开的内容用div标签，v-for循环
         3. 点击分类项，展示对应的内容，点击内容可以进行跳转

         数据来源于NavServiceList：
         ① 当含有children时，左侧列表此项可展开展示children的内容，右侧为children的data数据
         ② 当含有data时，左侧列表此项不可展开，右侧为data的数据
         ③ 若data为空,children不存在，直接跳转
        -->
        <!-- 先用死数据把分类组件写出来 -->
        <div class="mobile-menu-left">
          <el-menu
            class="el-menu-vertical-demo"
            active-text-color="#4E5969"
            text-color="#4E5969"
            :default-active="defauletActive"
            @open="handleOpen"
            @close="handleClose"
            @select="handleSelect"
          >
            <el-submenu style="background-color: #fff" ref="submenu" index="1">
              <template slot="title">
                <span>产品功能</span>
              </template>
              <el-menu-item
                :index="'1-' + index"
                :key="index"
                v-for="(item, index) in NavServiceList[0].children"
                style="
                  min-width: 124px;
                  padding: 0 30px;
                  height: 40px;
                  display: flex;
                  align-items: center;
                "
              >
                <img
                  v-if="NavSelectSubtitle == item.title"
                  src="../assets/icon/menuLine.svg"
                  alt="menuLine"
                  class="menu-line"
                />
                {{ item.title }}
              </el-menu-item>
            </el-submenu>
            <el-menu-item index="2">
              <!-- 当选中时才出现以下img -->
              <img
                v-if="NavSelectSubtitle == '服务支持'"
                src="../assets/icon/menuLine.svg"
                alt="menuLine"
                class="menu-line"
              />
              <span slot="title">服务支持</span>
            </el-menu-item>
            <el-menu-item index="3" @click="navigateTo('/DownloadCenter')">
              <span slot="title">下载中心 ↗</span>
            </el-menu-item>
            <el-menu-item index="4">
              <img
                v-if="NavSelectSubtitle == '定价'"
                src="../assets/icon/menuLine.svg"
                alt="menuLine"
                class="menu-line"
              />
              <span slot="title">定价</span>
            </el-menu-item>
          </el-menu>
        </div>

        <div
          class="mobile-menu-right"
          style="overflow: auto; width: calc(100% - 124px)"
        >
          <div class="mobile-menu-item-title">
            {{ NavSelectSubtitle + "&nbsp&nbsp" }}
            <img
              src="../assets/icon/menuPath.svg"
              style="margin-top: 3px"
              alt="menuPath"
            />
          </div>
          <div
            class="mobile-menu__item"
            v-for="(item, index) in NavSelectRight"
            :key="index"
            @click="navigateTo(item.path)"
          >
            <!-- 跳转链接 -->
            {{ item.routerTitle }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import Vue from "vue";
import ElementUI from "element-ui";
import {
  BasicFeatures,
  ProjectManage,
  ResearchManage,
  CRMManage,
  OrganizationManage,
  OAApproval,
  ConferenceSystem,
  ChatSystem,
  EnterpriseCloudrives,
  ManageSystem,
} from "../Data/NavDetailData";
Vue.use(ElementUI);

export default {
  name: "TopNavMobile",
  data() {
    return {
      avatarImg: require("../assets/picture/user.svg"),
      isMenuOpen: false,
      NavServiceList: [
        {
          title: "产品功能",
          data: [],
          children: [
            {
              title: "基础功能",
              data: BasicFeatures,
            },
            {
              title: "项目管理",
              data: ProjectManage,
            },
            {
              title: "研究管理",
              data: ResearchManage,
            },
            {
              title: "CRM管理",
              data: CRMManage,
            },
            {
              title: "组织管理",
              data: OrganizationManage,
            },
            {
              title: "OA审批",
              data: OAApproval,
            },
            {
              title: "会议系统",
              data: ConferenceSystem,
            },
            {
              title: "聊天系统",
              data: ChatSystem,
            },
            {
              title: "企业云盘",
              data: EnterpriseCloudrives,
            },
            {
              title: "管理系统",
              data: ManageSystem,
            },
          ],
        },
        {
          title: "服务支持",
          data: [
            [
              {
                routerTitle: "帮助手册",
                path: "/HelpBook/1.1",
              },
            ],
            [
              {
                routerTitle: "联系我们",
                path: "/ContactUs",
              },
            ],
          ],
        },
        {
          title: "下载中心",
          path: "/DownloadCenter",
          data: [],
        },
        {
          title: "定价",
          data: [
            [
              {
                routerTitle: "套餐价格",
                path: "/packagePricing",
              },
            ],
            // [
            //   {
            //     routerTitle: "软件价格",
            //     path: "/softwarePricing",
            //   },
            // ],
          ],
        },
      ],
      NavSelectRight: [], // 选中的右侧数据
      NavSelectSubtitle: "", // 选中的右侧标题
      defauletActive: "1-0",
    };
  },
  // 设计个人的记录网站，自定义分类，时间，内容，标签；首要就是方便自己记录
  methods: {
    ToHome() {
      this.$router.push("/");
    },
    handleCommand(command) {
      if (command === "register") {
        window.open(
          "https://saas.teamco.host:10998/saas/enterprise/#/register-index?loginTitle=Teamco&tenantVersionType=2"
        );
      } else if (command === "login") {
        window.open("https://teamco.com.cn:81/tc/");
      }
    },
    toggleMenu() {
      // 顶部导航栏的背景色变为白色
      this.$el.style.backgroundColor = this.isMenuOpen ? "#eff7ff" : "#fff";
      this.$el.style.border = this.isMenuOpen
        ? "1px solid #eff7ff"
        : "1px solid #ebeef5";
      // 顶部导航栏
      this.isMenuOpen = !this.isMenuOpen;
    },
    navigateTo(route) {
      this.$router.push(route);
      this.isMenuOpen = false;
    },
    handleClickOutside(event) {
      if (!this.$el.contains(event.target) && this.isMenuOpen) {
        this.isMenuOpen = false;
      }
    },
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleSelect(key, keyPath) {
      if (keyPath.length === 1) {
        this.NavSelectRight = this.NavServiceList[
          Number(keyPath[0]) - 1
        ].data.reduce((pre, cur) => {
          return pre.concat(cur);
        }, []);
      }

      if (keyPath.length === 2) {
        this.NavSelectRight = this.NavServiceList[
          Number(keyPath[0]) - 1
        ].children[Number(keyPath[1].split("-")[1])].data.reduce((pre, cur) => {
          return pre.concat(cur);
        }, []);
      }

      this.NavSelectSubtitle = this.NavServiceList[Number(keyPath[0]) - 1]
        .children
        ? this.NavServiceList[Number(keyPath[0]) - 1].children[
            Number(keyPath[1].split("-")[1])
          ].title
        : this.NavServiceList[Number(keyPath[0]) - 1].title;
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);

    // 手动初始化默认选中的数据
    const [parentIndex, childIndex] = this.defauletActive.split("-").map(Number);
    const parentItem = this.NavServiceList[parentIndex - 1];
    if(parentItem.children) {
      const childItem = parentItem.children[childIndex];
      this.NavSelectSubtitle = childItem.title;
      this.NavSelectRight = childItem.data.reduce((pre, cur) => {
        return pre.concat(cur);
      }, []);
    }
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
.TopNav {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 75px;
  transition: background-color 0.3s;
  z-index: 1000;
}

.NavBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 0 10px;
}

.NavBox__left img {
  height: 30px;
}

.NavBox__right {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
}

.NavBox__right__icon {
  margin-top: 3px;
}

/* 头像背景 */
.avatar {
  background-color: #ffffff;
}

/* 新增左侧移动端菜单样式 */
.menu-line {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

::v-deep .el-menu-item {
  min-width: 124px;
  padding: 0 30px;
  height: 40px;
  display: flex;
  align-items: center;
  line-height: normal;
}

::v-deep .el-menu-item.is-active {
  background-color: #fff !important;
}

::v-deep .el-menu-item,
.el-submenu__title {
  line-height: normal;
  background-color: #f1f4f7 !important;
}

/* 选中时，整个el-menu背景都为#fff */
::v-deep .el-menu {
  background-color: #fff;
}

::v-deep .el-submenu__title {
  min-width: 124px;
  padding: 0 30px;
  height: 40px;
  display: flex;
  align-items: center;
  line-height: normal;
  background: #f1f4f7;
}

::v-deep .is-opened {
  background: #fff;
}

/* 新增右侧移动端菜单样式 */
.mobile-menu {
  position: fixed;
  top: 75px;
  left: 0;
  width: 100%;
  height: calc(100vh - 75px);
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 999;
  display: flex;
}

.mobile-menu-left {
  overflow: auto;
  scrollbar-width: none;
  width: 124px;
  background-color: #f1f4f7;
}

.mobile-menu__item {
  height: 36px;
  padding-left: 40px;
  cursor: pointer;
  transition: background 0.3s;
  display: flex;
  align-items: center;

  font-size: 14px;
  color: rgba(78, 89, 105, 1);
}

.mobile-menu__item:last-child {
  border-bottom: none;
}

.mobile-menu__item:hover {
  background: #ffffff;
}

/* 添加过渡动画 */
.slide-down-enter-active,
.slide-down-leave-active {
  transition: all 0.3s ease;
}

.slide-down-enter,
.slide-down-leave-to {
  transform: translateY(-20px);
  opacity: 0;
}

/* 菜单样式 */
.mobile-menu-item-title {
  height: 60px;
  display: flex;
  align-items: center;
  padding-left: 24px;
  margin-bottom: 14px;
  font-size: 14px;
  font-weight: 500;
  color: rgba(78, 89, 105, 1);
  border-bottom: 1px solid #e5e6eb;
}

/* 汉堡菜单的样式，以及动画 */
/* From Uiverse.io by Cevorob */
.burger {
  position: relative;
  width: 24px;
  height: 18px;
  background: transparent;
  cursor: pointer;
  display: block;
}

.burger input {
  display: none;
}

.burger span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #4E5969;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.35s ease-in-out;
}

.burger span:nth-of-type(1) {
  top: 0px;
  transform-origin: left center;
}

.burger span:nth-of-type(2) {
  top: 50%;
  transform: translateY(-50%);
  transform-origin: left center;
}

.burger span:nth-of-type(3) {
  top: 100%;
  transform-origin: left center;
  transform: translateY(-100%);
}

.burger input:checked ~ span:nth-of-type(1) {
  transform: rotate(45deg);
  top: 0px;
  left: 5px;
}

.burger input:checked ~ span:nth-of-type(2) {
  width: 0%;
  opacity: 0;
}

.burger input:checked ~ span:nth-of-type(3) {
  transform: rotate(-45deg);
  top: 17px;
  left: 5px;
}
</style>
