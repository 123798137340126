<template>
  <div class="freedom-combo">
    <!-- 容器背景，可选 -->
    <div class="combo-wrapper">
      <el-card class="box-card">
        <!-- 选择版本（单选） -->
        <el-form label-position="left" label-width="120px">
          <div class="version">
            <div class="card-title">
              <span class="sign">1</span>
              <span>选择您的团队人数</span>
            </div>
            <div class="version-card">
              <div
                v-for="version in versions"
                :key="version.value"
                class="version-card-item"
                :style="{ border: version.selected ? '2px solid #165DFF' : '' }"
                @click="toggleTeam(version)"
              >
                <div class="version-card-content">
                  <span class="version-name">{{ version.label }}</span>
                  <span class="version-num">{{ version.num }}人起售</span>
                </div>
                <!-- 选中时卡片右上角显示单选按钮 -->
                <div v-if="version.selected" class="version-card-selected">
                  <i class="el-icon-check"></i>
                </div>
              </div>
            </div>
          </div>

          <!-- 选择功能（多选卡片） -->
          <div class="card-title" style="margin-top: 40px;">
            <span class="sign">2</span>
            <span>选择产品(可多选)</span>
          </div>
          <div class="feature-container">
            <div
              v-for="feature in features"
              :key="feature.id"
              class="feature-card"
              :class="{ selected: feature.selected }"
              @click="toggleFeature(feature)"
            >
              <div style="display: flex; flex-direction: row;">
                <img class="feature-icon" :src="feature.icon" alt="icon" />

              <div class="feature-header">
                <span class="feature-name">{{ feature.name }}</span>
                <!-- 若当前为移动设备时，不显示以下内容 -->
                <div class="feature-desc" v-if="!isMobile()">
                  {{ feature.desc }}
                </div>
                <span class="feature-price" v-if="!isMobile()">
                  {{ feature.price }}
                  <span class="feature-price-unit"> 元/人/年</span>
                </span>
              </div>
              </div>
              <div v-if="isMobile()" style="display: flex; justify-content: flex-end;align-items: flex-end;">
                <span>→</span>
              </div>
            </div>
          </div>
          <!-- 3. 合计价格 & 操作按钮 -->
          <div class="footer-section">
            <div class="price-info">
              价格
              <span class="price">¥{{ totalPrice }}/年</span>
            </div>
            <div class="ButtonBox">
              <button class="demoButton" @click="submitOrder" disabled="false">立即购买</button>
              <button class="registerButton" @click="viewDetail" disabled="false">
                联系我们
              </button>
            </div>
          </div>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "FreedomCombo",
  props: {
    PricingList: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      // 版本列表（单选）
      versions: [
        {
          value: "basic",
          label: "团队版",
          num: 20,
          selected: true,
          price: 200,
        },
        {
          value: "enterprise",
          label: "企业版",
          num: 30,
          selected: false,
          price: 2000,
        },
        {
          value: "flagship",
          label: "旗舰版",
          num: 50,
          selected: false,
          price: 5000,
        },
      ],
      // 默认选中的版本
      selectedVersion: "enterprise",

      // 功能列表（多选）
      features: [
        {
          id: 1,
          name: "项目管理",
          price: 100,
          desc: "轻松创建、分配、追踪和管理项目任务。通过实时更新任务进度、提醒功能以及团队...",
          selected: false,
          icon: require("../assets/icon/icon1.png"),
        },
        {
          id: 2,
          name: "研发管理",
          price: 100,
          desc: "无论是标准的敏捷研发管理，还是传统的瀑布式开发， 都能提供强大的工具支持。",
          selected: false,
          icon: require("../assets/icon/icon2.png"),
        },
        {
          id: 3,
          name: "财务中心",
          price: 100,
          desc: "图形化自定义表单及流程编辑器，轻松创建人事、行政、财务等各类管理流程，高效...",
          selected: false,
          icon: require("../assets/icon/icon2.png"),
        },
        {
          id: 4,
          name: "报表中心",
          price: 100,
          desc: "轻松创建、分配、追踪和管理项目任务。通过实时更新任务进度、提醒功能以及团队...",
          selected: false,
          icon: require("../assets/icon/icon2.png"),
        },
        {
          id: 5,
          name: "组织管理",
          price: 100,
          desc: "部门设置、角色权限管理和员工档案管理",
          selected: false,
          icon: require("../assets/icon/icon2.png"),
        },
        {
          id: 6,
          name: "CRM管理",
          price: 100,
          desc: "客户管理、销售线索跟进与商机管理",
          selected: false,
          icon: require("../assets/icon/icon2.png"),
        },
        {
          id: 7,
          name: "OA审批",
          price: 100,
          desc: "线上流程审批和审批管理",
          selected: false,
          icon: require("../assets/icon/icon2.png"),
        },
        {
          id: 8,
          name: "会议系统",
          price: 100,
          desc: "视频会议、会议纪要与协作功能",
          selected: false,
          icon: require("../assets/icon/icon2.png"),
        },
      ],
    };
  },
  computed: {
    // 实时计算合计金额：版本价格 + 已选功能价格
    totalPrice() {
      const currentVersion = this.versions.find(
        (v) => v.value === this.selectedVersion
      );
      // 版本价格 * 1（或可结合人数，具体业务需求）
      let sum = currentVersion ? currentVersion.price : 0;

      // 累加所有已选功能的价格
      this.features.forEach((feature) => {
        if (feature.selected) {
          sum += feature.price;
        }
      });
      return sum;
    },
  },
  methods: {
    // 切换版本
    handleVersionChange(val) {
      this.selectedVersion = val;
    },
    toggleTeam(version) {
      this.versions.forEach((v) => {
        v.selected = v.value === version.value;
      });
    },
    // 点击卡片，切换选中状态
    toggleFeature(feature) {
      feature.selected = !feature.selected;
    },
    // 提交订单
    submitOrder() {
      alert(`订单提交成功，合计 ¥${this.totalPrice} /年`);
    },
    // 查看详情
    viewDetail() {
      alert("查看详情，敬请期待...");
    },
    isMobile() {   //判断是否为移动端
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;  
      return /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile/.test(userAgent);  
    },
  },
};
</script>

<style lang="less" scoped>
/* 整体布局 */
.freedom-combo {
  width: 100%;
  margin: 0 auto;
}

/* 外层包装，控制最大宽度与居中 */
.combo-wrapper {
  max-width: 1200px;
  margin: 0 auto;
}

/* 卡片容器样式 */
.box-card {
  padding: 20px;
  border-radius: 8px;
}

/* 标题 */
.title {
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 30px;
}

/* =============== 通用样式 =============== */
.sign {
  display: inline-block;
  width: 24px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  border-radius: 50%;
  background: rgba(240, 240, 242, 1);
  color: rgba(78, 89, 105, 1);
  font-size: 16px;
  margin-right: 10px;
}

/* 版本标题 */
.card-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}

/* =============== 版本选择区域 =============== */
.version {
  margin-bottom: 20px;
}

/* 版本卡片 */
.version-card {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  margin-left: 34px;
}

.version-card-item {
  width: 180px;
  height: 68px;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.version-card-item:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}

.version-card-content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.version-name {
  font-size: 16px;
  font-weight: 500;
}

.version-num {
  font-size: 14px;
  color: #666;
}

.version-card-selected {
  position: relative;
  background: #165dff;
  color: #fff;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}

.el-icon-check {
  line-height: 3;
  font-weight: 600;
}
/* =============== 功能卡片区域 =============== */
.feature-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  grid-gap: 20px;
  margin-bottom: 30px;
  margin-left: 34px;
}

/* 卡片样式 */
.feature-card {
  position: relative;
  border: 2.5px solid #e5e6eb;
  border-radius: 6px;
  padding: 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  background-color: #fff;
  display: flex;
  overflow: hidden;
  justify-content: space-between;
}
.feature-card:hover {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
}
.feature-card.selected {
  border: 2.5px solid #165dff;
}
.feature-card.selected::before {
  content: url("../assets/icon/select.svg");
  position: absolute;
  top: -12px;
  right: -26px;
  z-index: 1;
  padding: 14px 22px 2px;
  background-color: #165dff;
  transform: rotate(45deg);
  font-size: 12px;
  color: #ffffff;
}

/* 卡片头部，图标 + 标题 + 价格 */
.feature-header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-bottom: 8px;
}
.feature-icon {
  width: 46px;
  height: 46px;
  margin-right: 10px;
  object-fit: contain;
}
.feature-name {
  font-size: 16px;
  font-weight: 500;
  flex: 1;
  margin-right: 10px;
}
.feature-price {
  color: #f53f3f;
  font-weight: 500;
  font-size: 26px;
  margin-top: 12px
}
.feature-price-unit {
  font-size: 12px;
  color: #666;
}

/* 卡片描述 */
.feature-desc {
  font-size: 14px;
  color: #666;
  line-height: 1.6;
  margin-top: 5px;
}

/* =============== 底部合计 & 按钮 =============== */
.footer-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 15px;
  margin-left: 34px;
}
.price-info {
  font-size: 18px;
  color: #666;
  display: flex;
  flex-direction: column;
}
.price {
  color: #f53f3f;
  font-size: 22px;
  font-weight: 600;
}

/* 按钮组 */
.ButtonBox {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.registerButton {
  width: 88px;
  height: 38px;
  color: rgba(54, 94, 255, 1);
  border-radius: 4px;
  border: 1px solid rgba(54, 94, 255, 1);
  background-color: transparent;
  font-size: 14px;
  // cursor: pointer;
  cursor: not-allowed;
  display: flex;
  align-items: center;
  justify-content: center;
}
.registerButton:hover {
  background-color: #e6ebff;
}

.demoButton {
  width: 88px;
  height: 38px;
  border-radius: 4px;
  background: rgba(54, 94, 255, 1);
  border: 0px;
  color: rgba(255, 255, 255, 1);
  font-size: 14px;
  // cursor: pointer;
  cursor: not-allowed;
  display: flex;
  align-items: center;
  justify-content: center;
}
.demoButton:hover {
  background: linear-gradient(
    156.15deg,
    rgba(54, 94, 255, 1) 0%,
    rgba(115, 173, 255, 1) 100%
  );
}
/* =============== 响应式适配 =============== */
@media (max-width: 768px) {
  .title {
    font-size: 20px;
  }
  .version-selector {
    flex-direction: column;
    align-items: center;
  }
  .feature-container {
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }
  .footer-section {
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
