import Vue from 'vue';
import Router from 'vue-router';
import QianYiHomePage from '../views/QianYiHomePage.vue';

const CustomWorkbench = () => import('../components/Basic/CustomWorkbench.vue');
const PendingWorkbench = () => import('../components/Basic/PendingWorkbench.vue');
const ViewsEditors = () => import('../components/Basic/ViewsEditors.vue');
const Schedule = () => import('../components/Basic/Schedule.vue');
const LogCenter = () => import('../components/Basic/LogCenter.vue');
const MyConcern = () => import('../components/Basic/MyConcern.vue');
const Attendance = () => import('../components/Basic/Attendance.vue');
const MyAchievement = () => import('../components/Basic/MyAchievement.vue');

const Business = () => import('../components/ProjectManage/Business.vue');
const FollowRecorded = () => import('../components/ProjectManage/FollowRecorded.vue');
const ProgressRecorded = () => import('../components/ProjectManage/ProgressRecorded.vue');
const ProjectAccount = () => import('../components/ProjectManage/ProjectAccount.vue');
const ProjectChatgroups = () => import('../components/ProjectManage/ProjectChatgroups.vue');
const ProjectDynamic = () => import('../components/ProjectManage/ProjectDynamic.vue');
const ProjectList = () => import('../components/ProjectManage/ProjectList.vue');
const ProjectLog = () => import('../components/ProjectManage/ProjectLog.vue');
const ProjectOrganization = () => import('../components/ProjectManage/ProjectOrganization.vue');
const ProjectPlan = () => import('../components/ProjectManage/ProjectPlan.vue');
const TaskList = () => import('../components/ProjectManage/TaskList.vue');
const WorkTimeManage = () => import('../components/ProjectManage/WorkTimeManage.vue');

const RDBusiness = () => import('../components/RD/Business.vue');
const RDFollowRecorded = () => import('../components/RD/FollowRecorded.vue');
const RDProgressRecorded = () => import('../components/RD/ProgressRecorded.vue');
const RDAccount = () => import('../components/RD/RDAccount.vue');
const RDChatgroups = () => import('../components/RD/RDChatgroups.vue');
const RDDynamic = () => import('../components/RD/RDDynamic.vue');
const RDList = () => import('../components/RD/RDList.vue');
const RDLog = () => import('../components/RD/RDLog.vue');
const RDOrganization = () => import('../components/RD/RDOrganization.vue');
const RDPlan = () => import('../components/RD/RDPlan.vue');
const RDTaskList = () => import('../components/RD/TaskList.vue');
const RDWorkTimeManage = () => import('../components/RD/WorkTimeManage.vue');

const ActivateBusiness = () => import('../components/CRM/ActivateBusiness.vue');
const ActiveCustomers = () => import('../components/CRM/ActiveCustomers.vue');
const AllBusiness = () => import('../components/CRM/AllBusiness.vue');
const AllContracts = () => import('../components/CRM/AllContracts.vue');
const AllCustomers = () => import('../components/CRM/AllCustomers.vue');
const ArchiveContracts = () => import('../components/CRM/ArchiveContracts.vue');
const BusinessGroup = () => import('../components/CRM/BusinessGroup.vue');
const ClosingBusiness = () => import('../components/CRM/ClosingBusiness.vue');
const ClosingRecorded = () => import('../components/CRM/ClosingRecorded.vue');
const CustomersGroup = () => import('../components/CRM/CustomersGroup.vue');
const DoubtfulDebt = () => import('../components/CRM/DoubtfulDebt.vue');
const CRMFollowRecorded = () => import('../components/CRM/FollowRecorded.vue');
const NormalReceivable = () => import('../components/CRM/NormalReceivable.vue');
const PayableManagement = () => import('../components/CRM/PayableManagement.vue');
const PayableTotal = () => import('../components/CRM/PayableTotal.vue');
const PerformanceContracts = () => import('../components/CRM/PerformanceContracts.vue');
const PresignedContracts = () => import('../components/CRM/PresignedContracts.vue');
const PurchaseContracts = () => import('../components/CRM/PurchaseContracts.vue');

const OrFollowRecorded = () => import('../components/OrganizationalManagement/FollowRecorded.vue');
const MatterCenter = () => import('../components/OrganizationalManagement/MatterCenter.vue');
const MemberPerformance = () => import('../components/OrganizationalManagement/MemberPerformance.vue');
const MemberWorkTime = () => import('../components/OrganizationalManagement/MemberWorkTime.vue');
const MemberLog = () => import('../components/OrganizationalManagement/MemberLog.vue');
const OrganizationAccount = () => import('../components/OrganizationalManagement/OrganizationAccount.vue');
const OrganizationConfiguration = () => import('../components/OrganizationalManagement/OrganizationConfiguration.vue');
const OrganizationMember = () => import('../components/OrganizationalManagement/OrganizationMember.vue');
const OrProgressRecord = () => import('../components/OrganizationalManagement/ProgressRecord.vue');
const ScheduleProgress = () => import('../components/OrganizationalManagement/ScheduleProgress.vue');

const ApprovalOrderConfiguration = () => import('../components/OA/ApprovalOrderConfiguration.vue');
const ApprovalPending = () => import('../components/OA/ApprovalPending.vue');
const CarbonCopyMine = () => import('../components/OA/CarbonCopyMine.vue');
const CustomForms = () => import('../components/OA/CustomForms.vue');
const InitiateApproval = () => import('../components/OA/InitiateApproval.vue');
const Initiated = () => import('../components/OA/Initiated.vue');
const Pended = () => import('../components/OA/Pended.vue');
const Pending = () => import('../components/OA/Pending.vue');
const WorkflowConfiguration = () => import('../components/OA/WorkflowConfiguration.vue');
const WorkflowEngine = () => import('../components/OA/WorkflowEngine.vue');

const BookMeet = () => import('../components/ConferenceSystem/BookMeet.vue');
const BusinessIntegration = () => import('../components/ConferenceSystem/BusinessIntegration.vue');
const FastConferenc = () => import('../components/ConferenceSystem/FastConferenc.vue');
const MeetingControl = () => import('../components/ConferenceSystem/MeetingControl.vue');
const MeetingSignIn = () => import('../components/ConferenceSystem/MeetingSignIn.vue');
const MeetingRecording = () => import('../components/ConferenceSystem/MeetingRecording.vue');
const MeetingWhiteboard = () => import('../components/ConferenceSystem/MeetingWhiteboard.vue');
const Programe = () => import('../components/ConferenceSystem/Programe.vue');
const SharePeople = () => import('../components/ConferenceSystem/SharePeople.vue');

const ChatBusinessIntegration = () => import('../components/ChatSystem/BusinessIntegration.vue');
const ChatFiles = () => import('../components/ChatSystem/ChatFiles.vue');
const ChatHistory = () => import('../components/ChatSystem/ChatHistory.vue');
const Contacts = () => import('../components/ChatSystem/Contacts.vue');
const GroupsManage = () => import('../components/ChatSystem/GroupsManage.vue');
const InstantCommunication = () => import('../components/ChatSystem/InstantCommunication.vue');
const MeetingInitiation = () => import('../components/ChatSystem/MeetingInitiation.vue');
const MessageNotifications = () => import('../components/ChatSystem/MessageNotifications.vue');
const OrganizeGroupChat = () => import('../components/ChatSystem/OrganizeGroupChat.vue');
const ProjectGroups = () => import('../components/ChatSystem/ProjectGroups.vue');
const VideoChat = () => import('../components/ChatSystem/VideoChat.vue');
const VoiceChat = () => import('../components/ChatSystem/VoiceChat.vue');

const ApplicationSpace = () => import('../components/EnterpriseCloudDisks/ApplicationSpace.vue');
const AttributeConfiguration = () => import('../components/EnterpriseCloudDisks/AttributeConfiguration.vue');
const EnterpriseSpace = () => import('../components/EnterpriseCloudDisks/EnterpriseSpace.vue');
const FileAssociations = () => import('../components/EnterpriseCloudDisks/FileAssociations.vue');
const FilePermissions = () => import('../components/EnterpriseCloudDisks/FilePermissions.vue');
const FileSharing = () => import('../components/EnterpriseCloudDisks/FileSharing.vue');
const PersonalSpace = () => import('../components/EnterpriseCloudDisks/PersonalSpace.vue');
const SpacePermissions = () => import('../components/EnterpriseCloudDisks/SpacePermissions.vue');
const UploadDownload = () => import('../components/EnterpriseCloudDisks/UploadDownload.vue');
const VersionManagement = () => import('../components/EnterpriseCloudDisks/VersionManagement.vue');
const WXDocumentsFiling = () => import('../components/EnterpriseCloudDisks/WXDocumentsFiling.vue');

const BackApplicationManage = () => import('../components/BackgroundManage/ApplicationManage.vue');
const CompanyInformation = () => import('../components/BackgroundManage/CompanyInformation.vue');
const BackLogCenter = () => import('../components/BackgroundManage/LogCenter.vue');
const MemberManage = () => import('../components/BackgroundManage/MemberManage.vue');
const RolePermissions = () => import('../components/BackgroundManage/RolePermissions.vue');
const ServiceConfiguration = () => import('../components/BackgroundManage/ServiceConfiguration.vue');
const StorageManagement = () => import('../components/BackgroundManage/StorageManagement.vue');
const TemplateSettings = () => import('../components/BackgroundManage/TemplateSettings.vue');

const CommunicationManagement = () => import('../components/Solution/CommunicationManagement.vue');
const ContractManagement = () => import('../components/Solution/ContractManagement.vue');
const CustomersManage = () => import('../components/Solution/CustomersManage.vue');
const MeetingManagement = () => import('../components/Solution/MeetingManagement.vue');
const OAApproval = () => import('../components/Solution/OAApproval.vue');
const OpportunityManagement = () => import('../components/Solution/OpportunityManagement.vue');
const OrganizatioManagement = () => import('../components/Solution/OrganizatioManagement.vue');
const PerformanceManagement = () => import('../components/Solution/PerformanceManagement.vue');
const ProjectControl = () => import('../components/Solution/ProjectControl.vue');
const RDManagement = () => import('../components/Solution/RDManagement.vue');
const ReceivablesPayables = () => import('../components/Solution/ReceivablesPayables.vue');
const ResourceManagement = () => import('../components/Solution/ResourceManagement.vue');
const ScheduleManagement = () => import('../components/Solution/ScheduleManagement.vue');
const TimeManagement = () => import('../components/Solution/TimeManagement.vue');

const ArchitecturalDesign = () => import('../components/SolutionIndustry/ArchitecturalDesign.vue');
const BridgeEngineering = () => import('../components/SolutionIndustry/BridgeEngineering.vue');
const Construction = () => import('../components/SolutionIndustry/Construction.vue');
const EducationIndustry = () => import('../components/SolutionIndustry/EducationIndustry.vue');
const EngineeringDesign = () => import('../components/SolutionIndustry/EngineeringDesign.vue');
const Internet = () => import('../components/SolutionIndustry/Internet.vue');
const ProfessionalServices = () => import('../components/SolutionIndustry/ProfessionalServices.vue');
const WaterConservancy = () => import('../components/SolutionIndustry/WaterConservancy.vue');

const ProductFunction = () => import('../views/ProductFunction.vue');
const VideoDetail = () => import('../views/VideoDetail.vue');
const ContactUs = () => import('../views/ContactUs.vue');
const MoreVideo = () => import('../views/MoreVideo.vue');
const Tutorial = () => import('../views/Tutorial.vue');
const HelpBook = () => import('../views/HelpBook.vue');
const DownloadCenter = () => import('../views/DownloadCenter.vue');
const packagePricing = () => import('../views/packagePricing.vue');
const softwarePricing = () => import('../views/softwarePricing.vue');

const Book1 = () => import('../components/Books/Login/Book1.vue');
const Book2 = () => import('../components/Books/Login/Book2.vue');
const Book3 = () => import('../components/Books/Login/Book3.vue');

const Book2_1 = () => import('../components/Books/EnterpriseManage/Book1.vue');
const Book2_2 = () => import('../components/Books/EnterpriseManage/Book2.vue');

const Book3_1 = () => import('../components/Books/AccountSetting/Book1.vue');
const Book3_2 = () => import('../components/Books/AccountSetting/Book2.vue');

const Book4_1 = () => import('../components/Books/ManageSystem/Book1.vue');
const Book4_2 = () => import('../components/Books/ManageSystem/Book2.vue');
const Book4_3 = () => import('../components/Books/ManageSystem/Book3.vue');
const Book4_4 = () => import('../components/Books/ManageSystem/Book4.vue');
const Book4_5 = () => import('../components/Books/ManageSystem/Book5.vue');
const Book4_6 = () => import('../components/Books/ManageSystem/Book6.vue');
const Book4_7 = () => import('../components/Books/ManageSystem/Book7.vue');
const Book4_8 = () => import('../components/Books/ManageSystem/Book8.vue');
const Book4_9 = () => import('../components/Books/ManageSystem/Book9.vue');
const Book4_10 = () => import('../components/Books/ManageSystem/Book10.vue');

const Book5_1 = () => import('../components/Books/BasicApplications/Book1.vue');
const Book5_2 = () => import('../components/Books/BasicApplications/Book2.vue');
const Book5_3 = () => import('../components/Books/BasicApplications/Book3.vue');
const Book5_4 = () => import('../components/Books/BasicApplications/Book4.vue');
const Book5_5 = () => import('../components/Books/BasicApplications/Book5.vue');
const Book5_6 = () => import('../components/Books/BasicApplications/Book6.vue');
const Book5_7 = () => import('../components/Books/BasicApplications/Book7.vue');
const Book5_8 = () => import('../components/Books/BasicApplications/Book8.vue');
const Book5_9 = () => import('../components/Books/BasicApplications/Book9.vue');
const Book5_10 = () => import('../components/Books/BasicApplications/Book10.vue');

const Book6_1 = () => import('../components/Books/ProjectManage/Book1.vue');
const Book6_2 = () => import('../components/Books/ProjectManage/Book2.vue');
const Book6_3 = () => import('../components/Books/ProjectManage/Book3.vue');
const Book6_4 = () => import('../components/Books/ProjectManage/Book4.vue');
const Book6_5 = () => import('../components/Books/ProjectManage/Book5.vue');
const Book6_6 = () => import('../components/Books/ProjectManage/Book6.vue');
const Book6_7 = () => import('../components/Books/ProjectManage/Book7.vue');
const Book6_8 = () => import('../components/Books/ProjectManage/Book8.vue');
const Book6_9 = () => import('../components/Books/ProjectManage/Book9.vue');
const Book6_10 = () => import('../components/Books/ProjectManage/Book10.vue');
const Book6_11 = () => import('../components/Books/ProjectManage/Book11.vue');
const Book6_12 = () => import('../components/Books/ProjectManage/Book12.vue');
const Book6_13 = () => import('../components/Books/ProjectManage/Book13.vue');
const Book6_14 = () => import('../components/Books/ProjectManage/Book14.vue');

const Book7_1 = () => import('../components/Books/ResearchManage/Book1.vue');
const Book7_2 = () => import('../components/Books/ResearchManage/Book2.vue');
const Book7_3 = () => import('../components/Books/ResearchManage/Book3.vue');
const Book7_4 = () => import('../components/Books/ResearchManage/Book4.vue');
const Book7_5 = () => import('../components/Books/ResearchManage/Book5.vue');
const Book7_6 = () => import('../components/Books/ResearchManage/Book6.vue');
const Book7_7 = () => import('../components/Books/ResearchManage/Book7.vue');
const Book7_8 = () => import('../components/Books/ResearchManage/Book8.vue');
const Book7_9 = () => import('../components/Books/ResearchManage/Book9.vue');
const Book7_10 = () => import('../components/Books/ResearchManage/Book10.vue');
const Book7_11 = () => import('../components/Books/ResearchManage/Book11.vue');
const Book7_12 = () => import('../components/Books/ResearchManage/Book12.vue');

const Book8_1 = () => import('../components/Books/CRM/Book1.vue');
const Book8_2 = () => import('../components/Books/CRM/Book2.vue');
const Book8_3 = () => import('../components/Books/CRM/Book3.vue');

const Book9_1 = () => import('../components/Books/ContractManage/Book1.vue');
const Book9_2 = () => import('../components/Books/ContractManage/Book2.vue');

const Book10_1 = () => import('../components/Books/OA/Book1.vue');
const Book10_2 = () => import('../components/Books/OA/Book2.vue');

const Book11_1 = () => import('../components/Books/OrganizationManage/Book1.vue');
const Book11_2 = () => import('../components/Books/OrganizationManage/Book2.vue');
const Book11_3 = () => import('../components/Books/OrganizationManage/Book3.vue');

const Book12_1 = () => import('../components/Books/FinancialManage/Book1.vue');
const Book12_2 = () => import('../components/Books/FinancialManage/Book2.vue');

const Book13_1 = () => import('../components/Books/Administration/Book1.vue');
const Book13_2 = () => import('../components/Books/Administration/Book2.vue');
const Book13_3 = () => import('../components/Books/Administration/Book3.vue');

const Book14_1 = () => import('../components/Books/CloudDisks/Book1.vue');
const Book14_2 = () => import('../components/Books/CloudDisks/Book2.vue');
const Book14_3 = () => import('../components/Books/CloudDisks/Book3.vue');
const Book14_4 = () => import('../components/Books/CloudDisks/Book4.vue');


Vue.use(Router);
const router = new Router({
  mode: 'hash',
  routes: [
    {
      path: '/',
      name: 'home',
      component: QianYiHomePage
    },
    {
      path: '/ProductFunction',
      name: 'ProductFunction',
      component: ProductFunction,
      children: [
        {
          path: 'CustomWorkbench',
          name: 'CustomWorkbench',
          component: CustomWorkbench
        },
        {
          path: 'PendingWorkbench',
          name: 'PendingWorkbench',
          component: PendingWorkbench
        },
        {
          path: 'ViewsEditors',
          name: 'ViewsEditors',
          component: ViewsEditors
        },
        {
          path: 'Schedule',
          name: 'Schedule',
          component: Schedule
        },
        {
          path: 'LogCenter',
          name: 'LogCenter',
          component: LogCenter
        },
        {
          path: 'MyConcern',
          name: 'MyConcern',
          component: MyConcern
        },
        {
          path: 'Attendance',
          name: 'Attendance',
          component: Attendance
        },
        {
          path: 'MyAchievement',
          name: 'MyAchievement',
          component: MyAchievement
        },
        {
          path: 'Business',
          name: 'Business',
          component: Business
        },
        {
          path: 'FollowRecorded',
          name: 'FollowRecorded',
          component: FollowRecorded
        },
        {
          path: 'ProgressRecorded',
          name: 'ProgressRecorded',
          component: ProgressRecorded
        },
        {
          path: 'ProjectAccount',
          name: 'ProjectAccount',
          component: ProjectAccount
        },
        {
          path: 'ProjectChatgroups',
          name: 'ProjectChatgroups',
          component: ProjectChatgroups
        },
        {
          path: 'ProjectDynamic',
          name: 'ProjectDynamic',
          component: ProjectDynamic
        },
        {
          path: 'ProjectList',
          name: 'ProjectList',
          component: ProjectList
        },
        {
          path: 'ProjectLog',
          name: 'ProjectLog',
          component: ProjectLog
        },
        {
          path: 'ProjectOrganization',
          name: 'ProjectOrganization',
          component: ProjectOrganization
        },
        {
          path: 'ProjectPlan',
          name: 'ProjectPlan',
          component: ProjectPlan
        },
        {
          path: 'TaskList',
          name: 'TaskList',
          component: TaskList
        },
        {
          path: 'WorkTimeManage',
          name: 'WorkTimeManage',
          component: WorkTimeManage
        },
        {
          path: 'RDBusiness',
          name: 'RDBusiness',
          component: RDBusiness
        },
        {
          path: 'RDFollowRecorded',
          name: 'RDFollowRecorded',
          component: RDFollowRecorded
        },
        {
          path: 'RDProgressRecorded',
          name: 'RDProgressRecorded',
          component: RDProgressRecorded
        },
        {
          path: 'RDAccount',
          name: 'RDAccount',
          component: RDAccount
        },
        {
          path: 'RDChatgroups',
          name: 'RDChatgroups',
          component: RDChatgroups
        },
        {
          path: 'RDDynamic',
          name: 'RDDynamic',
          component: RDDynamic
        },
        {
          path: 'RDList',
          name: 'RDList',
          component: RDList
        },
        {
          path: 'RDLog',
          name: 'RDLog',
          component: RDLog
        },
        {
          path: 'RDOrganization',
          name: 'RDOrganization',
          component: RDOrganization
        },
        {
          path: 'RDPlan',
          name: 'RDPlan',
          component: RDPlan
        },
        {
          path: 'RDTaskList',
          name: 'RDTaskList',
          component: RDTaskList
        },
        {
          path: 'RDWorkTimeManage',
          name: 'RDWorkTimeManage',
          component: RDWorkTimeManage
        },
        {
          path: 'ActivateBusiness',
          name: 'ActivateBusiness',
          component: ActivateBusiness
        },
        {
          path: 'ActiveCustomers',
          name: 'ActiveCustomers',
          component: ActiveCustomers
        },
        {
          path: 'AllBusiness',
          name: 'AllBusiness',
          component: AllBusiness
        },
        {
          path: 'AllContracts',
          name: 'AllContracts',
          component: AllContracts
        },
        {
          path: 'AllCustomers',
          name: 'AllCustomers',
          component: AllCustomers
        },
        {
          path: 'ArchiveContracts',
          name: 'ArchiveContracts',
          component: ArchiveContracts
        },
        {
          path: 'BusinessGroup',
          name: 'BusinessGroup',
          component: BusinessGroup
        },
        {
          path: 'ClosingBusiness',
          name: 'ClosingBusiness',
          component: ClosingBusiness
        },
        {
          path: 'ClosingRecorded',
          name: 'ClosingRecorded',
          component: ClosingRecorded
        },
        {
          path: 'CustomersGroup',
          name: 'CustomersGroup',
          component: CustomersGroup
        },
        {
          path: 'DoubtfulDebt',
          name: 'DoubtfulDebt',
          component: DoubtfulDebt
        },
        {
          path: 'CRMFollowRecorded',
          name: 'CRMFollowRecorded',
          component: CRMFollowRecorded
        },
        {
          path: 'NormalReceivable',
          name: 'NormalReceivable',
          component: NormalReceivable
        },
        {
          path: 'PayableManagement',
          name: 'PayableManagement',
          component: PayableManagement
        },
        {
          path: 'PayableTotal',
          name: 'PayableTotal',
          component: PayableTotal
        },
        {
          path: 'PerformanceContracts',
          name: 'PerformanceContracts',
          component: PerformanceContracts
        },
        {
          path: 'PresignedContracts',
          name: 'PresignedContracts',
          component: PresignedContracts
        },
        {
          path: 'PurchaseContracts',
          name: 'PurchaseContracts',
          component: PurchaseContracts
        },
        {
          path: 'OrFollowRecorded',
          name: 'OrFollowRecorded',
          component: OrFollowRecorded
        },
        {
          path: 'MatterCenter',
          name: 'MatterCenter',
          component: MatterCenter
        },
        {
          path: 'MemberPerformance',
          name: 'MemberPerformance',
          component: MemberPerformance
        },
        {
          path: 'MemberWorkTime',
          name: 'MemberWorkTime',
          component: MemberWorkTime
        },
        {
          path: 'MemberLog',
          name: 'MemberLog',
          component: MemberLog
        },
        {
          path: 'OrganizationAccount',
          name: 'OrganizationAccount',
          component: OrganizationAccount
        },
        {
          path: 'OrganizationConfiguration',
          name: 'OrganizationConfiguration',
          component: OrganizationConfiguration
        },
        {
          path: 'OrganizationMember',
          name: 'OrganizationMember',
          component: OrganizationMember
        },
        {
          path: 'OrProgressRecord',
          name: 'OrProgressRecord',
          component: OrProgressRecord
        },
        {
          path: 'ScheduleProgress',
          name: 'ScheduleProgress',
          component: ScheduleProgress
        },
        {
          path: 'ApprovalOrderConfiguration',
          name: 'ApprovalOrderConfiguration',
          component: ApprovalOrderConfiguration
        },
        {
          path: 'ApprovalPending',
          name: 'ApprovalPending',
          component: ApprovalPending
        },
        {
          path: 'CarbonCopyMine',
          name: 'CarbonCopyMine',
          component: CarbonCopyMine
        },
        {
          path: 'CustomForms',
          name: 'CustomForms',
          component: CustomForms
        },
        {
          path: 'InitiateApproval',
          name: 'InitiateApproval',
          component: InitiateApproval
        },
        {
          path: 'Initiated',
          name: 'Initiated',
          component: Initiated
        },
        {
          path: 'Pended',
          name: 'Pended',
          component: Pended
        },
        {
          path: 'Pending',
          name: 'Pending',
          component: Pending
        },
        {
          path: 'WorkflowConfiguration',
          name: 'WorkflowConfiguration',
          component: WorkflowConfiguration
        },
        {
          path: 'WorkflowEngine',
          name: 'WorkflowEngine',
          component: WorkflowEngine
        },
        {
          path: 'BookMeet',
          name: 'BookMeet',
          component: BookMeet
        },
        {
          path: 'BusinessIntegration',
          name: 'BusinessIntegration',
          component: BusinessIntegration
        },
        {
          path: 'FastConferenc',
          name: 'FastConferenc',
          component: FastConferenc
        },
        {
          path: 'MeetingControl',
          name: 'MeetingControl',
          component: MeetingControl
        },
        {
          path: 'MeetingSignIn',
          name: 'MeetingSignIn',
          component: MeetingSignIn
        },
        {
          path: 'MeetingRecording',
          name: 'MeetingRecording',
          component: MeetingRecording
        },
        {
          path: 'MeetingWhiteboard',
          name: 'MeetingWhiteboard',
          component: MeetingWhiteboard
        },
        {
          path: 'Programe',
          name: 'Programe',
          component: Programe
        },
        {
          path: 'SharePeople',
          name: 'SharePeople',
          component: SharePeople
        },
        {
          path: 'ChatBusinessIntegration',
          name: 'ChatBusinessIntegration',
          component: ChatBusinessIntegration
        },
        {
          path: 'ChatFiles',
          name: 'ChatFiles',
          component: ChatFiles
        },
        {
          path: 'ChatHistory',
          name: 'ChatHistory',
          component: ChatHistory
        },
        {
          path: 'Contacts',
          name: 'Contacts',
          component: Contacts
        },
        {
          path: 'GroupsManage',
          name: 'GroupsManage',
          component: GroupsManage
        },
        {
          path: 'InstantCommunication',
          name: 'InstantCommunication',
          component: InstantCommunication
        },
        {
          path: 'MeetingInitiation',
          name: 'MeetingInitiation',
          component: MeetingInitiation
        },
        {
          path: 'MessageNotifications',
          name: 'MessageNotifications',
          component: MessageNotifications
        },
        {
          path: 'OrganizeGroupChat',
          name: 'OrganizeGroupChat',
          component: OrganizeGroupChat
        },
        {
          path: 'ProjectGroups',
          name: 'ProjectGroups',
          component: ProjectGroups
        },
        {
          path: 'VideoChat',
          name: 'VideoChat',
          component: VideoChat
        },
        {
          path: 'VoiceChat',
          name: 'VoiceChat',
          component: VoiceChat
        },
        {
          path: 'ApplicationSpace',
          name: 'ApplicationSpace',
          component: ApplicationSpace
        },
        {
          path: 'AttributeConfiguration',
          name: 'AttributeConfiguration',
          component: AttributeConfiguration
        },
        {
          path: 'EnterpriseSpace',
          name: 'EnterpriseSpace',
          component: EnterpriseSpace
        },
        {
          path: 'FileAssociations',
          name: 'FileAssociations',
          component: FileAssociations
        },
        {
          path: 'FilePermissions',
          name: 'FilePermissions',
          component: FilePermissions
        },
        {
          path: 'FileSharing',
          name: 'FileSharing',
          component: FileSharing
        },
        {
          path: 'PersonalSpace',
          name: 'PersonalSpace',
          component: PersonalSpace
        },
        {
          path: 'SpacePermissions',
          name: 'SpacePermissions',
          component: SpacePermissions
        },
        {
          path: 'UploadDownload',
          name: 'UploadDownload',
          component: UploadDownload
        },
        {
          path: 'VersionManagement',
          name: 'VersionManagement',
          component: VersionManagement
        },
        {
          path: 'WXDocumentsFiling',
          name: 'WXDocumentsFiling',
          component: WXDocumentsFiling
        },
        {
          path: 'BackApplicationManage',
          name: 'BackApplicationManage',
          component: BackApplicationManage
        },
        {
          path: 'CompanyInformation',
          name: 'CompanyInformation',
          component: CompanyInformation
        },
        {
          path: 'BackLogCenter',
          name: 'BackLogCenter',
          component: BackLogCenter
        },
        {
          path: 'MemberManage',
          name: 'MemberManage',
          component: MemberManage
        },
        {
          path: 'RolePermissions',
          name: 'RolePermissions',
          component: RolePermissions
        },
        {
          path: 'ServiceConfiguration',
          name: 'ServiceConfiguration',
          component: ServiceConfiguration
        },
        {
          path: 'StorageManagement',
          name: 'StorageManagement',
          component: StorageManagement
        },
        {
          path: 'TemplateSettings',
          name: 'TemplateSettings',
          component: TemplateSettings
        },
      ]
    },

    {
      path: '/CommunicationManagement',
      name: 'CommunicationManagement',
      component: CommunicationManagement
    },
    {
      path: '/ContractManagement',
      name: 'ContractManagement',
      component: ContractManagement
    },
    {
      path: '/CustomersManage',
      name: 'CustomersManage',
      component: CustomersManage
    },
    {
      path: '/MeetingManagement',
      name: 'MeetingManagement',
      component: MeetingManagement
    },
    {
      path: '/OAApproval',
      name: 'OAApproval',
      component: OAApproval
    },
    {
      path: '/OpportunityManagement',
      name: 'OpportunityManagement',
      component: OpportunityManagement
    },
    {
      path: '/OrganizatioManagement',
      name: 'OrganizatioManagement',
      component: OrganizatioManagement
    },
    {
      path: '/PerformanceManagement',
      name: 'PerformanceManagement',
      component: PerformanceManagement
    },
    {
      path: '/ProjectControl',
      name: 'ProjectControl',
      component: ProjectControl
    },
    {
      path: '/RDManagement',
      name: 'RDManagement',
      component: RDManagement
    },
    {
      path: '/ReceivablesPayables',
      name: 'ReceivablesPayables',
      component: ReceivablesPayables
    },
    {
      path: '/ResourceManagement',
      name: 'ResourceManagement',
      component: ResourceManagement
    },
    {
      path: '/ScheduleManagement',
      name: 'ScheduleManagement',
      component: ScheduleManagement
    },
    {
      path: '/TimeManagement',
      name: 'TimeManagement',
      component: TimeManagement
    },
    {
      path: '/ArchitecturalDesign',
      name: 'ArchitecturalDesign',
      component: ArchitecturalDesign
    },
    {
      path: '/BridgeEngineering',
      name: 'BridgeEngineering',
      component: BridgeEngineering
    },
    {
      path: '/Construction',
      name: 'Construction',
      component: Construction
    },
    {
      path: '/EducationIndustry',
      name: 'EducationIndustry',
      component: EducationIndustry
    },
    {
      path: '/EngineeringDesign',
      name: 'EngineeringDesign',
      component: EngineeringDesign
    },
    {
      path: '/Internet',
      name: 'Internet',
      component: Internet
    },
    {
      path: '/ProfessionalServices',
      name: 'ProfessionalServices',
      component: ProfessionalServices
    },
    {
      path: '/WaterConservancy',
      name: 'WaterConservancy',
      component: WaterConservancy
    },
    {
      path: '/VideoDetail',
      name: 'VideoDetail',
      component: VideoDetail
    },
    {
      path: '/ContactUs',
      name: 'ContactUs',
      component: ContactUs
    },
    {
      path: '/MoreVideo',
      name: 'MoreVideo',
      component: MoreVideo
    },
    {
      path: '/Tutorial',
      name: 'Tutorial',
      component: Tutorial
    },
    {
      path: '/DownloadCenter',
      name: 'DownloadCenter',
      component: DownloadCenter
    },
    {
      path: '/packagePricing',
      name: 'packagePricing',
      component: packagePricing
    },
    {
      path: '/softwarePricing',
      name: 'softwarePricing',
      component: softwarePricing
    },
    {
      path: '/HelpBook',
      name: 'HelpBook',
      component: HelpBook,
      children: [
        {
          path: '1.1',
          component: Book1
        },
        {
          path: '1.2',
          component: Book2
        },
        {
          path: '1.3',
          component: Book3
        },
        {
          path: '2.1',
          component: Book2_1
        },
        {
          path: '2.2',
          component: Book2_2
        },
        {
          path: '3.1',
          component: Book3_1
        },
        {
          path: '3.2',
          component: Book3_2
        },
        {
          path: '4.1',
          component: Book4_1
        },
        {
          path: '4.2',
          component: Book4_2
        },
        {
          path: '4.3',
          component: Book4_3
        },
        {
          path: '4.4',
          component: Book4_4
        },
        {
          path: '4.5',
          component: Book4_5
        },
        {
          path: '4.6',
          component: Book4_6
        },
        {
          path: '4.7',
          component: Book4_7
        },
        {
          path: '4.8',
          component: Book4_8
        },
        {
          path: '4.9',
          component: Book4_9
        },
        {
          path: '4.10',
          component: Book4_10
        },
        {
          path: '5.1',
          component: Book5_1
        },
        {
          path: '5.2',
          component: Book5_2
        },
        {
          path: '5.3',
          component: Book5_3
        },
        {
          path: '5.4',
          component: Book5_4
        },
        {
          path: '5.5',
          component: Book5_5
        },
        {
          path: '5.6',
          component: Book5_6
        },
        {
          path: '5.7',
          component: Book5_7
        },
        {
          path: '5.8',
          component: Book5_8
        },
        {
          path: '5.9',
          component: Book5_9
        },
        {
          path: '5.10',
          component: Book5_10
        },
        {
          path: '6.1',
          component: Book6_1
        },
        {
          path: '6.2',
          component: Book6_2
        },
        {
          path: '6.3',
          component: Book6_3
        },
        {
          path: '6.4',
          component: Book6_4
        },
        {
          path: '6.5',
          component: Book6_5
        },
        {
          path: '6.6',
          component: Book6_6
        },
        {
          path: '6.7',
          component: Book6_7
        },
        {
          path: '6.8',
          component: Book6_8
        },
        {
          path: '6.9',
          component: Book6_9
        },
        {
          path: '6.10',
          component: Book6_10
        },
        {
          path: '6.11',
          component: Book6_11
        },
        {
          path: '6.12',
          component: Book6_12
        },
        {
          path: '6.13',
          component: Book6_13
        },
        {
          path: '6.14',
          component: Book6_14
        },
        {
          path: '7.1',
          component: Book7_1
        },
        {
          path: '7.2',
          component: Book7_2
        },
        {
          path: '7.3',
          component: Book7_3
        },
        {
          path: '7.4',
          component: Book7_4
        },
        {
          path: '7.5',
          component: Book7_5
        },
        {
          path: '7.6',
          component: Book7_6
        },
        {
          path: '7.7',
          component: Book7_7
        },
        {
          path: '7.8',
          component: Book7_8
        },
        {
          path: '7.9',
          component: Book7_9
        },
        {
          path: '7.10',
          component: Book7_10
        },
        {
          path: '7.11',
          component: Book7_11
        },
        {
          path: '7.12',
          component: Book7_12
        },
        {
          path: '8.1',
          component: Book8_1
        },
        {
          path: '8.2',
          component: Book8_2
        },
        {
          path: '8.3',
          component: Book8_3
        },
        {
          path: '9.1',
          component: Book9_1
        },
        {
          path: '9.2',
          component: Book9_2
        },
        {
          path: '10.1',
          component: Book10_1
        },
        {
          path: '10.2',
          component: Book10_2
        },
        {
          path: '11.1',
          component: Book11_1
        },
        {
          path: '11.2',
          component: Book11_2
        },
        {
          path: '11.3',
          component: Book11_3
        },
        {
          path: '12.1',
          component: Book12_1
        },
        {
          path: '12.2',
          component: Book12_2
        },
        {
          path: '13.1',
          component: Book13_1
        },
        {
          path: '13.2',
          component: Book13_2
        },
        {
          path: '13.3',
          component: Book13_3
        },
        {
          path: '14.1',
          component: Book14_1
        },
        {
          path: '14.2',
          component: Book14_2
        },
        {
          path: '14.3',
          component: Book14_3
        },
        {
          path: '14.4',
          component: Book14_4
        },
      ]
    },
  ]
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0); // 滚动到页面顶部
  next(); // 必须调用 next 方法
});

export default router;