<template>
  <div class="PricingCard">
    <div class="section_4">
      <div class="PricingBox">
        <div class="PricingCardBox">
          <div class="PricingCardBoxFont">为您的组织选择最佳方案</div>
          <div class="PricingCard">
            <div
              :class="{
                'move-left': movingLeft,
                'move-right': movingRight,
                [card.cardClass]: true,
              }"
              v-for="(card, index) in pricingCard"
            >
              <div class="PricingPreferential">
                <div class="PricingPreferentialTitle">{{ card.title }}</div>
              </div>
              <div class="PricingCardItemTop">
                <div class="TeamCoKind">{{ card.version }}</div>
                <div class="PeopleTotal">{{ card.peopleTotal }}</div>
                <div class="PriceKind">
                  <div class="PriceNumber">{{ card.priceNumber }}</div>
                  <div class="PriceUnit" v-if="card.priceUnit != ''">
                    {{ card.priceUnit }}
                  </div>
                </div>
                <div
                  class="FreeUseButton"
                  :style="card.freeUseButton != '' ? '' : 'visibility: hidden;'"
                  @click="openDialog(card)"
                >
                  {{ card.freeUseButton }}
                </div>
                <hr v-if="card.priceUnit != ''" />
              </div>
              <div class="PricingCardItemBottom">
                <div class="BuyContentList">
                  <div
                    class="BuyContentItem"
                    v-for="item in card.BuyContentList"
                  >
                    <div class="BuyContentItemName">
                      {{ item.BuyContentItemName }}
                    </div>
                    <div
                      class="BuyContentItemNumber"
                      v-if="item.BuyContentItemNumber != ''"
                    >
                      {{ item.BuyContentItemNumber }}
                    </div>
                    <img
                      src="../assets/picture/GoldChecked.png"
                      class="BuyContentItemActive"
                      v-else-if="card.goldChecked === true"
                    />
                    <img
                      src="../assets/picture/GreenChecked.png"
                      class="BuyContentItemActive"
                      v-else
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="选择套餐" :visible.sync="dialogFormVisible">
      <!-- 给子组件传入选中的pricingList数据，子组件中判断是否父组件是否传了
           有数据，有则子组件选中套餐，没有说明是在软件价格页面点击的按钮，则用户自行选择 
      -->
      <PricingSelect :pricingList="selectedPricingList" />
    </el-dialog>
  </div>
</template>
<script>
import {
  BuyContentList1,
  BuyContentList2,
  BuyContentList3,
  BuyContentList4,
  BuyContentList5,
} from "../Data/pricingData.js";
import PricingSelect from "./PricingSelect.vue";

export default {
  components: { PricingSelect },
  data() {
    return {
      selected: 0,
      isFooterFixed: false,
      timerId: null,
      BuyContentList1,
      BuyContentList2,
      BuyContentList3,
      BuyContentList4,
      BuyContentList5,

      movingRight: false,
      movingLeft: false,

      // 需要更换的数据：
      /**
       * 1. 第一个div的class名；
       * 2. title(免费试用3个月)
       * 3. 版本
       * 4. 限制人数
       * 5. 价格(值可以为按需订阅)
       * 6. button文字
       * 7. BuyContentList的数据
       */
      pricingCard: [
        {
          cardClass: "PricingCardItem",
          title: "标准授权使用",
          version: "个人版",
          peopleTotal: "免费10人",
          priceNumber: "0",
          priceUnit: "元/年",
          freeUseButton: "免费使用",
          BuyContentList: BuyContentList1,
          goldChecked: false,
        },
        {
          cardClass: "PricingCardItem2",
          title: "免费试用3个月",
          version: "基础版",
          peopleTotal: "限30人起",
          priceNumber: "按需订阅",
          priceUnit: "",
          freeUseButton: "",
          BuyContentList: BuyContentList2,
          goldChecked: false,
        },
        {
          cardClass: "PricingCardItem3",
          title: "免费试用3个月",
          version: "团队版",
          peopleTotal: "限50人起",
          priceNumber: "按需订阅",
          priceUnit: "",
          freeUseButton: "",
          BuyContentList: BuyContentList3,
          goldChecked: false,
        },
        {
          cardClass: "PricingCardItem4",
          title: "免费试用3个月",
          version: "企业版",
          peopleTotal: "限50人起",
          priceNumber: "按需订阅",
          priceUnit: "",
          freeUseButton: "",
          BuyContentList: BuyContentList4,
          goldChecked: false,
        },
        {
          cardClass: "PricingCardItem5",
          title: "免费试用3个月",
          version: "旗舰版",
          peopleTotal: "限100人起",
          priceNumber: "按需订阅",
          priceUnit: "",
          freeUseButton: "",
          BuyContentList: BuyContentList5,
          goldChecked: true,
        },
      ],
      dialogFormVisible: false,
      selectedPricingList: [],
    };
  },
  methods: {
    renderCard() {
      // 每个卡片宽度296px，动态修改卡片的left值，右边卡片可叠加在左边卡片上，占满宽度1200px，
      // 当鼠标移动至A卡片时，展示完整内容，将A卡片左边的卡片向左移，将A卡片右边的卡片向右移
      // 左右两边要移动的left值为: 卡片被覆盖的宽度/卡片数量 = 原卡片left值 + 卡片宽度 - 基础位置
      const cardWidth = 296;
      const cardContainerWidth = 1200;
      const cardGap = 14; // 卡片间隙,不设置最后一个卡片会被遮挡

      const cardNum = this.pricingCard.length;
      const cardContainer = this.$el.querySelector(".PricingCard");

      // 计算基础位置
      const basePosition = cardContainerWidth / cardNum - cardGap;

      this.pricingCard.forEach((item, index) => {
        // 设置卡片的初始位置
        const cardItems = cardContainer.querySelector("." + item.cardClass);
        cardItems.style.left = `${basePosition * index}px`;

        // 添加鼠标移入移出事件
        cardItems.addEventListener("mouseenter", () => {
          // 设置右边卡片的left值
          for (let i = index - 1; i >= 0; i--) {
            const cardItemsLeft = cardContainer.querySelector(
              "." + this.pricingCard[i].cardClass
            );
            cardItemsLeft.style.left = `${
              Number(cardItemsLeft.style.left.split("px")[0]) -
              (cardWidth + basePosition) / cardNum
            }px`;
          }
          // 设置左边卡片的left值
          for (let i = index + 1; i < cardNum; i++) {
            const cardItemsRight = cardContainer.querySelector(
              "." + this.pricingCard[i].cardClass
            );
            cardItemsRight.style.left = `${
              Number(cardItemsRight.style.left.split("px")[0]) +
              (cardWidth + basePosition) / cardNum
            }px`;
          }
        });
        cardItems.addEventListener("mouseleave", () => {
          // 复原
          for (let i = 0; i < cardNum; i++) {
            const cardItems = cardContainer.querySelector(
              "." + this.pricingCard[i].cardClass
            );
            cardItems.style.left = `${basePosition * i}px`;
          }
        });
      });
    },

    openDialog(card) {
      if (card.version === "个人版") {
        window.open(
          "https://saas.teamco.host:10998/saas/enterprise/#/register-index?loginTitle=Teamco&tenantVersionType=2"
        );
      } else {
        this.dialogFormVisible = true;
        this.selectedPricingList = card.BuyContentList;
      }
    },
  },
  mounted() {
    // 初始化 PricingCard 的位置
    this.$nextTick(() => {
      const cardContainer = this.$el.querySelector(".PricingCard");
      cardContainer.style.left = "0px";
    });

    this.renderCard();
  },
};
</script>

<style lang="less" scoped>
// 第四板块
.section_4 {
  // min-width: 1200px;

  > .PricingBox {
    width: 1200px;
    margin: 0 auto;
    height: 1496px;
    display: flex;
    justify-content: center;

    .MoveLeft,
    .MoveRight {
      height: 100%;
      display: flex;
      margin: 0 auto;
      > img {
        margin: auto 0;
      }
    }
    > .PricingCardBox {
      width: 1200px;
      overflow: hidden;

      > .PricingCardBoxFont {
        text-align: center;
        font-size: 40px;
        font-weight: 700;
        margin-top: 100px;
      }

      > .PricingCard {
        display: flex;
        margin-top: 40px;
        padding: 40px 20px 80px;
        position: relative;
        height: 1152px;

        > .PricingCardItem {
          position: absolute;
          transform: translateY(-50%);
          top: 50%;
          left: 0;
          transition: left 0.5s ease-in-out;
          border-radius: 12px;
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 0px 20px rgba(29, 33, 41, 0.08);
          width: 296px;
          height: 1152px;

          > .PricingPreferential {
            display: flex;
            > .PricingPreferentialTitle {
              margin-left: auto;
              width: 124px;
              height: 34px;
              border-radius: 0px 12px 0px 12px;
              background: rgba(229, 229, 229, 1);
              text-align: center;
              line-height: 34px;
              color: rgba(64, 64, 64, 1);
              font-size: 14px;
              font-weight: 400;
            }
          }

          > .PricingCardItemTop {
            padding: 0 24px;

            > .TeamCoKind {
              color: rgb(0, 0, 0);
              font-size: 22px;
              font-weight: 500;
            }

            > .PeopleTotal {
              font-size: 14px;
              font-weight: 400;
              color: rgb(0, 0, 0);
              margin-top: 8px;
            }

            > .PriceKind {
              height: 110px;
              display: flex;

              > .PriceNumber {
                font-size: 42px;
                font-weight: 700;
                color: rgba(29, 33, 41, 1);
                line-height: 116px;
              }

              > .PriceUnit {
                color: rgba(78, 89, 105, 1);
                font-size: 14px;
                font-weight: 400;
                line-height: 138px;
              }
            }

            > .FreeUseButton {
              width: 240px;
              height: 48px;
              border-radius: 6px;
              color: rgba(78, 89, 105, 1);
              border: 1px solid rgba(206, 208, 213, 1);
              text-align: center;
              line-height: 48px;
              cursor: pointer;
            }

            > hr {
              margin-top: 32px;
              border: none;
              height: 1px;
              background-color: rgba(229, 230, 235, 1);
            }
          }

          > .PricingCardItemBottom {
            padding: 0 24px;
            margin-top: 32px;

            > .BuyContentList {
              > .BuyContentItem {
                display: flex;
                margin-top: 12px;

                > .BuyContentItemName {
                  color: rgba(134, 144, 156, 1);
                  font-size: 14px;
                }

                > .BuyContentItemActive,
                > .BuyContentItemNumber {
                  margin-left: auto;
                  height: 10px;
                }

                > .BuyContentItemNumber {
                  color: rgba(60, 69, 87, 1);
                  font-size: 14px;
                }
              }
            }
          }
        }

        .PricingCardItem2,
        .PricingCardItem3,
        .PricingCardItem4,
        .PricingCardItem5 {
          position: absolute;
          transform: translateY(-50%);
          top: 50%;
          transition: left 0.5s ease-in-out;
          border-radius: 12px;
          background-repeat: no-repeat;
          box-shadow: 0px 0px 20px rgba(29, 33, 41, 0.08);
          width: 296px;
          height: 1152px;

          > .PricingPreferential {
            display: flex;

            > .PricingPreferentialTitle {
              margin-left: auto;
              width: 124px;
              height: 34px;
              border-radius: 0px 12px 0px 12px;
              background: rgba(255, 87, 51, 1);
              text-align: center;
              line-height: 34px;
              color: rgba(255, 238, 179, 1);
              font-size: 14px;
              font-weight: 400;
            }
          }

          > .PricingCardItemTop {
            padding: 0 24px;

            > .TeamCoKind {
              color: white;
              font-size: 22px;
              font-weight: 500;
            }

            > .PeopleTotal {
              font-size: 14px;
              font-weight: 400;
              color: white;
              margin-top: 8px;
            }

            > .PriceKind {
              height: 110px;
              display: flex;

              > .PriceNumber {
                line-height: 128px;
                font-size: 22px;
                font-weight: 500;
                color: rgba(255, 255, 255, 1);
              }

              > .PriceUnit {
                color: rgba(255, 255, 255, 1);
                font-size: 14px;
                font-weight: 400;
                line-height: 150px;
              }
            }

            > .FreeUseButton {
              width: 240px;
              height: 48px;
              color: rgba(255, 238, 179, 1);
              border-radius: 6px;
              background: linear-gradient(
                90deg,
                rgba(66, 28, 22, 1) 0%,
                rgba(0, 0, 0, 1) 100%
              );
              text-align: center;
              line-height: 48px;
              cursor: pointer;
            }
          }

          > .PricingCardItemBottom {
            border-radius: 12px;
            background: rgba(255, 255, 255, 1);
            margin-top: 32px;
            padding: 20px 20px;
            height: 875px;
            // box-shadow: 0px 0px 20px rgba(29, 33, 41, 0.08);

            > .BuyContentList {
              > .BuyContentItem {
                display: flex;
                margin-top: 12px;

                > .BuyContentItemName {
                  color: rgba(134, 144, 156, 1);
                  font-size: 14px;
                }

                > .BuyContentItemActive,
                > .BuyContentItemNumber {
                  margin-left: auto;
                  height: 10px;
                }

                > .BuyContentItemNumber {
                  color: rgba(60, 69, 87, 1);
                  font-size: 14px;
                }
              }
            }
          }
        }

        .PricingCardItem2 {
          background-image: url(../assets/picture/PricingCardImg2.png);
        }

        .PricingCardItem3 {
          background-image: url(../assets/picture/PricingCardImg3.png);
        }

        .PricingCardItem4 {
          background-image: url(../assets/picture/PricingCardImg4.png);
        }

        .PricingCardItem5 {
          background-image: url(../assets/picture/PricingCardImg5.png);

          > .PricingCardItemBottom {
            > .BuyContentList {
              > .BuyContentItem {
                > .BuyContentItemNumber {
                  color: rgba(158, 97, 36, 1);
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .section_4 {
    > .PricingBox {
      width: 100%;
    }
  }
}

@keyframes move-left {
  0% {
    transform: translate(0%, -50%) translateX(0);
  }
  100% {
    transform: translate(-50%, -50%) translateX(-100%);
  }
}

@keyframes move-right {
  0% {
    transform: translate(0%, -50%) translateX(-100%);
  }
  100% {
    transform: translate(-50%, -50%) translateX(50%);
  }
}

.move-left {
  animation: move-left 1s forwards;
}

.move-right {
  animation: move-right 1s forwards;
}

::v-deep .el-dialog {
  width: 100%;
  max-width: 1200px;
  min-width: 360px;
}

::v-deep .el-dialog__body {
  padding: 0;
  background: transparent;
}
</style>
