<template>
    <div class="QianYiHomePage">
        <TopNav />
        <div class="main">
            <!-- 第一板块 -->
            <div class="section_1">
                <div class="contentBox">
                    <div class="contentfont">
                        <div class="contentTitle">高效、便捷、一站式组织协同</div>
                        <div class="describe">
                            数字化时代，高效协同是组织提升竞争力的关键。千蚁云巢智能协同管理平台，为您打造一站式的项目管理、客户管理、合同管理、应收账款、云存储、会议沟通、聊天交流等全方位解决方案，提升工作效率。
                        </div>
                        <div class="ButtonBox">
                            <button class="registerButton"
                                onclick='window.open("https://saas.teamco.host:10998/saas/enterprise/#/register-index?loginTitle=Teamco&tenantVersionType=2")'>免费注册</button>
                            <button class="loginButton" style="margin-left: 18px;">预约演示</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 第二板块 -->
            <div class="section_2">
                <div class="contentBox_2">
                    <div class="contentfont_2">
                        <div class="contentTitle_2">一站式组织协同效能平台，覆盖组织常见协作场景</div>
                        <div class="describe_2">模板化事项管理，覆盖不同行业业务需求</div>
                        <div class="CarouselBox">
                            <div class="CarouselBoxleft">
                                <template v-for="(item, index) in CarouselSelectList">
                                    <div class="CarouselSelect" @mouseenter="resetTimer(index)"
                                        :style="{ backgroundImage: index === selected ? 'url(' + item.HoverCarouselSelect_Img + ')' : 'url(' + item.CarouselSelect_Img + ')' }">
                                        <div class="CarouselSelect_title">{{ item.CarouselSelect_title }}</div>
                                    </div>
                                </template>
                            </div>
                            <div class="CarouselBoxright">
                                <!-- <img src="../assets/picture/showview1.png" class="CarouselcontentImg">
                                <div class="CarouselcontentBox">
                                    <div class="CarouselcontentDescribe">
                                        基于工作场景的云存储、即时通讯及会议系统各，项目、任务、商机、合同、应收、回款、审批全流程协同。</div>
                                    <div class="Knowbutton">
                                        <span class="KnowbuttonTitle">了解更多</span>
                                        <img src="../assets/icon/knowmore.png" class="KnowbuttonImg">
                                    </div>
                                </div> -->
                                <template v-for="(item, index) in CarouselcontentBoxList">
                                    <img :src="item.CarouselcontentImg" class="CarouselcontentImg"
                                        v-if="index === selected">
                                    <div class="CarouselcontentBox" v-if="index === selected">
                                        <div class="CarouselcontentDescribe">{{ item.CarouselcontentDescribe }}</div>
                                        <div class="Knowbutton">
                                            <span class="KnowbuttonTitle">了解更多</span>
                                            <img src="../assets/icon/knowmore.png" class="KnowbuttonImg">
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <!-- <div class="CarouselBox">
                            <template v-for="(item, index) in CarouselSelectList">
                                <div class="CarouselSelect" @click="selected = index"
                                    :style="index === selected ? 'border-bottom-color: rgba(54, 94, 255, 1)' : 'border-bottom-color: rgba(229, 230, 235, 1)'">
                                    <img class="CarouselSelect_Img" :src="item.CarouselSelect_Img">
                                    <div class="CarouselSelect_title">{{ item.CarouselSelect_title }}</div>
                                </div>
                            </template>
                        </div>
                        <div class="Carouselcontent">
                            <template v-for="(item, index) in CarouselcontentBoxList">
                                <div class="CarouselcontentBox" v-if="index === selected">
                                    <div class="CarouselcontentTitle">{{ item.CarouselcontentTitle }}</div>
                                    <div class="CarouselcontentDescribe">{{ item.CarouselcontentDescribe }}</div>
                                    <div class="Knowbutton">
                                        <span class="KnowbuttonTitle">了解更多</span>
                                        <img src="../assets/icon/knowmore.png" class="KnowbuttonImg">
                                    </div>
                                </div>
                                <img :src="item.CarouselcontentImg" class="CarouselcontentImg"
                                    v-if="index === selected">
                            </template>
                        </div> -->
                    </div>
                </div>
            </div> 
            <!-- <deepseek /> -->
            <!-- 第三板块 -->
            <div class="section_3">
                <div class="contentBox_3">
                    <div class="contentfont_3">
                        <div class="contentTitle_3">
                            <div class="Bigtitle">高效协同，一应俱全，开箱即用</div>
                            <div class="Smalltitle">设计简洁优雅，颠覆传统工具体验</div>
                        </div>
                        <div class="ToolsBox">
                            <div class="Tools" v-for="(item, index) in ToolsList">
                                <div class="Toolleft">
                                    <div class="Toolstop">
                                        <div class="Tooltitle">{{ item.ToolTitle }}</div>
                                        <img src="../assets/icon/Selected2.png" class="Selected2">
                                    </div>
                                    <div class="Toolsline">
                                        <span class="Toolsline_left"></span>
                                        <span class="Toolsline_right"></span>
                                    </div>
                                    <div class="Toolsbottom">{{ item.ToolDescribe }}</div>
                                </div>
                                <div class="Toolright">
                                    <img :src="item.ToolImg">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 第四板块 -->
            <PricingCard v-if="windowWidth > 1200" />
            <PricingCardMobile v-else />

        </div>
        <Informationbar :class="{ 'fixed-footer': isFooterFixed }" />
        <Footer :class="{ 'fixed-footer': isFooterFixed }" />
        <RollTop />
    </div>
</template>

<script>
import TopNav from '../components/TopNav.vue';
import Footer from '../components/Footer.vue';
import RollTop from '../components/RollTop.vue';
import Informationbar from '../components/Informationbar.vue';
import PricingCard from '../components/PricingCard.vue';
import PricingCardMobile from '../components/PricingCardMobile.vue';
// import deepseek from '../components/deepseek.vue';
export default {
    components: { TopNav, Footer, Informationbar, RollTop, PricingCard, PricingCardMobile },

    data() {
        return {
            selected: 0, isFooterFixed: false, timerId: null, windowWidth: false,
            CarouselSelectList: [
                {
                    CarouselSelect_title: '一站式协同',
                    CarouselSelect_Img: require('../assets/picture/SelectBackgroundImg1.png'),
                    HoverCarouselSelect_Img: require('../assets/picture/SelectedBackgroundImg1.png')
                },
                {
                    CarouselSelect_title: '有始有终',
                    CarouselSelect_Img: require('../assets/picture/SelectBackgroundImg2.png'),
                    HoverCarouselSelect_Img: require('../assets/picture/SelectedBackgroundImg2.png')
                },
                {
                    CarouselSelect_title: '效能绩效',
                    CarouselSelect_Img: require('../assets/picture/SelectBackgroundImg3.png'),
                    HoverCarouselSelect_Img: require('../assets/picture/SelectedBackgroundImg3.png')
                },
                {
                    CarouselSelect_title: '知识沉淀',
                    CarouselSelect_Img: require('../assets/picture/SelectBackgroundImg4.png'),
                    HoverCarouselSelect_Img: require('../assets/picture/SelectedBackgroundImg4.png')
                },
                {
                    CarouselSelect_title: '把控全局',
                    CarouselSelect_Img: require('../assets/picture/SelectBackgroundImg5.png'),
                    HoverCarouselSelect_Img: require('../assets/picture/SelectedBackgroundImg5.png')
                }
            ],
            CarouselcontentBoxList: [
                {
                    CarouselcontentTitle: '一站式协同',
                    CarouselcontentDescribe: '基于工作场景的云存储、即时通讯及会议系统各，项目、任务、商机、合同、应收、回款、审批全流程协同。',
                    CarouselcontentImg: require('../assets/picture/showview1.png')
                },
                {
                    CarouselcontentTitle: '有始有终',
                    CarouselcontentDescribe: '从目标到项目，从线索到回款，从任务到验收，全程数字化跟进，每个环节均可审、可查，真正让工作件件有着落，事事有回音。',
                    CarouselcontentImg: require('../assets/picture/showview2.png')
                },
                {
                    CarouselcontentTitle: '效能绩效',
                    CarouselcontentDescribe: '建立可量化的评价标准，数据化呈现工作成效，凝聚核心人才。',
                    CarouselcontentImg: require('../assets/picture/showview3.png')
                },
                {
                    CarouselcontentTitle: '知识沉淀',
                    CarouselcontentDescribe: '零散文件统一沉淀，建立有序沉淀的组织智库，驱动组织创新增长。',
                    CarouselcontentImg: require('../assets/picture/showview4.png')
                },
                {
                    CarouselcontentTitle: '把控全局',
                    CarouselcontentDescribe: '信息云端同步，一站式待办管理中心，高效协作同步进展，快捷指派落实到人。',
                    CarouselcontentImg: require('../assets/picture/showview5.png')
                },
            ],
            ToolsList: [
                {
                    ToolTitle: '项目管理',
                    ToolDescribe: '轻松创建、分配、追踪和管理项目任务。通过实时更新任务进度、提醒功能以及团队成员间的协作沟通，确保项目按时按质完成，提高项目成功率。',
                    ToolImg: require('../assets/icon/icon1.png')
                },
                {
                    ToolTitle: '研发管理',
                    ToolDescribe: '无论是标准的敏捷研发管理，还是传统的瀑布式开发， 都能提供强大的工具支持。',
                    ToolImg: require('../assets/icon/icon2.png')
                },
                {
                    ToolTitle: 'CRM',
                    ToolDescribe: '将线索，客户，商机，合同，项目，应收，联系等环节信息数据紧密融合，实现完整闭环信息化管理。',
                    ToolImg: require('../assets/icon/icon3.png')
                },
                {
                    ToolTitle: '审批中心',
                    ToolDescribe: '图形化自定义表单及流程编辑器，轻松创建人事、行政、财务等各类管理流程，高效规范办事。',
                    ToolImg: require('../assets/icon/icon4.png')
                },
                {
                    ToolTitle: '组织管理',
                    ToolDescribe: '支持智能组织及非智能组织的管理，汇集组织各类事项及统计、成员日志等数据，使责权更明确，协作更方便，管理更精细及高效。',
                    ToolImg: require('../assets/icon/icon5.png')
                },
                {
                    ToolTitle: '自定义工作台',
                    ToolDescribe: '基于最前端的微前端技术，可以实现在一个界面上整合内部及外部应用，满足所有办公需求！便捷操作，提升团队生产力！',
                    ToolImg: require('../assets/icon/icon6.png')
                },
                {
                    ToolTitle: '会议系统',
                    ToolDescribe: '支持在线会议功能，让团队成员可以随时随地参与讨论和协作。通过共享屏幕、实时聊天、会议录制等功能，提高会议效率，确保信息传递准确无误。同时支持多人同时共享，会议主持人根据会议实际情况实时调控。',
                    ToolImg: require('../assets/icon/icon7.png')
                },
                {
                    ToolTitle: '即时聊天',
                    ToolDescribe: '内置即时聊天工具，支持一对一或群组聊天，方便团队成员之间快速沟通、分享文件和信息。同时，聊天记录可长期保存，方便随时查阅。',
                    ToolImg: require('../assets/icon/icon9.png')
                },
            ],
        }
    },
    methods: {
        Readyselect(index) {
            this.selected = index
        },
        adjustFooter() {
            const windowHeight = window.innerHeight;
            const contentHeight = this.$el.querySelector('.main').offsetHeight + 1500;
            if (contentHeight < windowHeight) {
                this.isFooterFixed = true;
            } else {
                this.isFooterFixed = false;
            }
        },
        startTimer() {
            this.timerId = setInterval(() => {
                if (this.selected < 4) {
                    this.selected++;
                } else {
                    this.selected = 0;
                }
            }, 3000);
        },
        resetTimer(index) {
            this.selected = index
            clearInterval(this.timerId);
            this.startTimer();
        },

        // updateButtonState() {
        //     this.disableLeft = this.positions.every(p => p <= 0);
        //     this.disableRight = this.positions.some(p => p + this.childWidth >= this.parentWidth);
        // }
    },
    mounted() {
        this.adjustFooter();
        window.addEventListener('resize', this.adjustFooter);

        // 获取窗口宽度
        this.windowWidth = window.innerWidth;

        // 监听窗口大小变化
        window.addEventListener('resize', () => {
            this.windowWidth = window.innerWidth;
        });

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // 当 section_2 进入视图时启动轮播
                    this.startTimer();
                } else {
                    // 当 section_2 离开视图时停止轮播
                    if (this.timerId) {
                        clearInterval(this.timerId);
                        this.timerId = null;
                    }
                }
            });
        }, {
            threshold: 0.1 // 当10%的内容可见时触发
        });

        // 观察 section_2
        const section2 = this.$el.querySelector('.section_2');
        if (section2) {
            observer.observe(section2);
        }

        // 保存observer引用以便清理
        this.observer = observer;
    },
    beforeDestroy() {
        // 清理定时器
        if (this.timerId) {
            clearInterval(this.timerId);
        }
        // 清理观察器
        if (this.observer) {
            this.observer.disconnect();
        }
        window.removeEventListener('resize', this.adjustFooter);
    },
}
</script>

<style lang="less" scoped>
.fixed-footer {
    position: fixed;
}

.section_1 {
    height: 800px;
    display: flex;
    // min-width: 1200px;
    width: 100%;
    background: linear-gradient(to bottom, rgb(239, 247, 255), rgb(251, 252, 255));
    justify-content: center;
    @media screen and (max-width: 768px) {
        height: auto;
        min-width: auto;
    }
}

.contentBox {
    width: 100%;
    max-width: 1920px;
    display: flex;
    align-items: center;
    flex-direction: column;
    background-image: url(../assets/picture/backgroundimg1.png);
    background-position: center center;
    @media screen and (max-width: 768px) {
        background-size: cover;
        background-position: top center;
        padding: 20px;
    }

    >.contentfont {
        padding-top: 120px;
        width: 95%;
        max-width: 1200px;
        @media screen and (max-width: 768px) {
            padding-top: 20px;
            width: 100%;
        }

        >.contentTitle {
            font-size: 52px;
            font-weight: 700;
            color: rgba(54, 94, 255, 1);
            @media screen and (max-width: 768px) {
                font-size: 36px;
                text-align: center;
            }
        }

        >.describe {
            margin-top: 10px;
            font-size: 20px;
            width: 682px;
            line-height: 28px;
            color: rgba(54, 94, 255, 1);
            text-align: justify;
            @media screen and (max-width: 768px) {
                font-size: 16px;
                width: 100%;
                text-align: center;
            }
        }

        >.ButtonBox {
            margin-top: 28px;
            display: flex;
            @media screen and (max-width: 768px) {
                flex-direction: column;
                align-items: center;
            }

            >.registerButton {
                width: 130px;
                height: 52px;
                opacity: 1;
                color: rgba(54, 94, 255, 1);
                border-radius: 4px;
                border: none;
                background-color: white;
                font-size: 14px;
                cursor: pointer;

                &:hover {
                    background-color: #E6EBFF;
                    border: 1px solid #365eff;
                }
                @media screen and (max-width: 768px) {
                    margin-bottom: 10px;
                }
            }

            >.loginButton {
                width: 130px;
                height: 52px;
                border-radius: 4px;
                background: rgba(54, 94, 255, 1);
                border: 0px;
                color: rgba(255, 255, 255, 1);
                font-size: 14px;
                justify-content: center;

                &:hover {
                    background: linear-gradient(156.15deg, rgba(54, 94, 255, 1) 0%, rgba(115, 173, 255, 1) 100%);
                }
            }
        }
    }
}
.section_2 {
    min-width: 1200px;
    height: 900px;
    display: flex;
    justify-content: center;

    @media screen and (max-width: 1200px) {
        min-width: 100%;
        height: auto;
        padding: 20px 0;
    }
}

.contentBox_2 {
    width: 100%;
    max-width: 1920px;
    display: flex;
    justify-content: center;

    >.contentfont_2 {
        padding-top: 100px;
        display: flex;
        flex-direction: column;
        width: 1200px;

        @media screen and (max-width: 1200px) {
            width: 100%;
            padding: 50px 15px 0;
        }

        >.contentTitle_2 {
            color: rgba(29, 33, 41, 1);
            height: 68px;
            font-size: 40px;
            text-align: center;
            font-weight: 700;

            @media screen and (max-width: 1200px) {
                font-size: 28px;
                height: auto;
                margin-bottom: 20px;
            }
        }

        >.describe_2 {
            text-align: center;
            font-size: 20px;
            color: rgba(78, 89, 105, 1);
            height: 85px;

            @media screen and (max-width: 1200px) {
                font-size: 16px;
                height: auto;
                margin-bottom: 30px;
            }
        }

        >.CarouselBox {
            display: flex;

            @media screen and (max-width: 1200px) {
                flex-direction: column;
                align-items: center;
            }

            >.CarouselBoxleft {
                width: 285px;

                @media screen and (max-width: 1200px) {
                    width: 100%;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                }

                >.CarouselSelect {
                    width: 182px;
                    height: 74px;
                    font-size: 20px;
                    font-weight: 400;
                    text-align: center;
                    line-height: 74px;
                    margin-bottom: 25px;

                    @media screen and (max-width: 1200px) {
                        width: 140px;
                        height: 60px;
                        background-size: 140px 60px;
                        font-size: 16px;
                        line-height: 60px;
                        margin: 5px;
                    }

                    @media screen and (max-width: 480px) {
                        width: 120px;
                        height: 50px;
                        background-size: 120px 50px;
                        font-size: 14px;
                        line-height: 50px;
                    }
                }
            }

            >.CarouselBoxright {
                display: flex;
                align-items: center;

                @media screen and (max-width: 1200px) {
                    flex-direction: column;
                    margin-top: 20px;
                }

                >.CarouselcontentImg {
                    width: 582px;
                    height: 358px;
                    box-shadow: 0px 8px 30px rgba(29, 33, 41, 0.06);

                    @media screen and (max-width: 1200px) {
                        width: 100%;
                        height: auto;
                        max-width: 400px;
                    }
                }

                >.CarouselcontentBox {
                    margin-left: 76px;

                    @media screen and (max-width: 1200px) {
                        margin-left: 0;
                        margin-top: 20px;
                        text-align: center;
                    }

                    >.CarouselcontentDescribe {
                        width: 234px;
                        font-size: 16px;
                        font-weight: 400;
                        color: rgba(78, 89, 105, 1);
                        text-align: justify;

                        @media screen and (max-width: 1200px) {
                            width: 100%;
                            max-width: 300px;
                            margin: 0 auto;
                            font-size: 14px;
                        }
                    }

                    >.Knowbutton {
                        width: 83px;
                        height: 30px;
                        border-radius: 5px;
                        background: rgba(54, 94, 255, 1);
                        font-size: 12px;
                        cursor: pointer;
                        color: rgba(255, 255, 255, 1);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-top: 20px;

                        @media screen and (max-width: 1200px) {
                            margin: 20px auto;
                        }
                    }
                }
            }
        }
    }
}
.section_3 {
    display: flex;
    justify-content: center;
    background-color: #eff7ff;
    width: 100%;
}

.contentBox_3 {
    background-image: url(../assets/picture/backgroundimg3.png);
    background-position: bottom left;
    background-repeat: no-repeat;
    width: 100%;
    display: flex;
    justify-content: center;
    // height: 1505px;
    >.contentfont_3 {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 100px;
        padding: 0 1rem;
        >.contentTitle_3 {
            padding: 2rem 0;

            >.Bigtitle {
                color: rgba(29, 33, 41, 1);
                font-size: clamp(1.5rem, 4vw, 2.5rem);
                font-weight: 700;
                text-align: center;
                margin-top: 60px;
            }

            >.Smalltitle {
                color: rgba(78, 89, 105, 1);
                font-size: clamp(1rem, 2vw, 1.25rem);
                margin-top: 0.625rem;
                text-align: center;
            }
        }

        >.ToolsBox {
            display: flex;
            flex-wrap: wrap;
            gap: 2rem;
            justify-content: center;
            align-items: center;
            max-width: 1200px;
            width: 100%;
            >.Tools {
                width: 100%;
                max-width: 582px;
                min-height: 253px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                border-radius: 1rem;
                background: rgba(255, 255, 255, 1);
                box-shadow: 0px 0px 20px rgba(29, 33, 41, 0.06);
                background-image: url(../assets/picture/BackgroundImg.png);
                background-repeat: no-repeat;
                background-position: 75% center;
                >.Toolleft {
                    flex: 1;
                    padding: 1.5rem;
                    min-width: 250px;

                    >.Toolstop {
                        display: flex;

                        >.Tooltitle {
                            font-size: clamp(1.25rem, 3vw, 1.5rem);
                            font-weight: 500;
                        }

                        >.Selected2 {
                            width: 1rem;
                            height: 1rem;
                            margin-top: 0.625rem;
                            margin-left: 0.5rem;
                        }
                    }

                    >.Toolsline {
                        display: flex;
                        margin-top: 1.25rem;
                        margin-bottom: 1.75rem;
                        >.Toolsline_left {
                            width: 36px;
                            height: 4px;
                            background: rgba(54, 94, 255, 1);
                        }

                        >.Toolsline_right {
                            width: 20px;
                            height: 4px;
                            background: rgba(255, 213, 4, 1);
                        }
                    }

                    >.Toolsbottom {
                        font-size: 0.875rem;
                        font-weight: 400;
                        width: 19.625rem;
                        line-height: 22px;
                        color: #4e5969;
                        text-align: justify;
                    }
                }

                >.Toolright {
                    margin-left: auto;
                    align-items: center;
                    display: flex;
                    width: 140px;
                    justify-content: center;

                    >img {
                        max-width: 100%;
                        height: auto;
                    }
                }
            }
        }
    }
}

@media (max-width: 768px) {
    // .section_3 {
    //     padding: 1rem;
    // }

    .contentBox_3 {
        >.contentfont_3 {
            >.contentTitle_3 {
                >.Bigtitle {
                    font-size: 1.5rem;
                }

                >.Smalltitle {
                    font-size: 1rem;
                }
            }

            >.ToolsBox {
                width: 100%;
                >.Tools {
                    background-size: 100% 100%;
                    flex-wrap: nowrap;
                    >.Toolleft {
                        padding: 1rem;

                        >.Toolsbottom {
                            width: 95%;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 375px) {
    .Tools {
        background-repeat: round;
    }
    
    .Toolleft {
        padding: 1rem;
    }
}
</style>
